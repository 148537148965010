import React, { Component } from 'react';
import './AddShop.css';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import { CONFIG } from '../../utils/config';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import NewWindow from 'react-new-window';

registerPlugin(FilePondPluginFileValidateType);

class AddShop extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addshop: false,
            isLoading: false,
            scheduleData: [],
            file: '', imagePreviewUrl: '', shopname: '', schedule: '', template: '',
            errors: {},
            showIntermediariesRules: false,
            showDirectRules: false
        };
        this.addshopPopup = this.addshopPopup.bind(this);
        this.addshopClose = this.addshopClose.bind(this);
        this.showDirectRulesPopup = this.showDirectRulesPopup.bind(this);
        this.showDirectRulesClose = this.showDirectRulesClose.bind(this);
        this.showIntermediariesRulesPopup = this.showIntermediariesRulesPopup.bind(this);
        this.showIntermediariesRulesClose = this.showIntermediariesRulesClose.bind(this);
    }
    handleInit() {


    }
    _handleSubmit(e) {
        e.preventDefault();
        const data = {
            "shopname": this.state.shopname,
            "templatename": localStorage.getItem('template'),
            //"schedulepattern": this.state.schedule
        }
        const token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        //debugger;
        if (this.validateForm()) {
            let fields = {};
            //let fields1 = {};
            let fields2 = {};
            fields = "";
            //fields1 = "";
            fields2 = "";
            //this.setState({ fields: fields, fields1: fields1, fields2: fields2 });
            this.setState({ fields: fields, fields2: fields2 });
            axios.post(CONFIG.API_URL + 'shop', data)
                .then(res => {
                    //this.setState({ addshop: false, schedule: '', shopname: '', template: '', errors: {} })
                    this.setState({ addshop: false, shopname: '', template: '', errors: {} })
                    localStorage.removeItem('template');
                    let path = `myshop`;

                    this.props.parm.history.push(path);
                    var isSearchPage = this.props.parm.location.pathname, expr = /myshop/;
                    if (isSearchPage.match(expr)) {
                        this.props.parentMethod();

                    }
                })
                .catch((err) => {
                    this.setState({
                        isLoading: false
                    });

                    if (err.response.status === 401) {
                        localStorage.removeItem('accessKey');
                        localStorage.removeItem('clientid');
                        localStorage.removeItem('clientname');
                        localStorage.removeItem('clientlogo');
                        localStorage.removeItem('role');
                        const { from } = this.props.location.state || { from: { pathname: "/" } };
                        this.props.history.push(from);
                    }
                    else {
                        alert(err.response.data);
                    }
                });
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    validateForm() {
        //debugger;
        let fields = this.state.shopname;
        //let fields1 = this.state.schedule;
        let fields2 = localStorage.getItem('template')
        let errors = {};
        let formIsValid = true;

        if (fields === '') {
            formIsValid = false;
            errors["shopname"] = "* Please enter your Shop Name";
        }

        // if (fields1 === '') {
        //     formIsValid = false;
        //     errors["schedule"] = "* Please Choose Schedule";
        // }

        if (!fields2) {
            formIsValid = false;
            errors["template"] = "* Please Upload Template";
        }

        this.setState({
            errors: errors
        });

        return formIsValid;
    }

    addshopPopup() {
        this.setState({
            addshop: true
        })
    }

    addshopClose() {
        localStorage.removeItem('template');
        // this.setState({
        //     addshop: false, schedule: '', shopname: '', template: '', errors: {}, files: ''
        // })
        this.setState({
            addshop: false, shopname: '', template: '', errors: {}, files: ''
        })
    }

    showDirectRulesPopup() {
        debugger;
        this.setState({
            showDirectRules: true
        })
    }

    showIntermediariesRulesPopup() {
        debugger;
        this.setState({
            showIntermediariesRules: true
        })
    }

    showDirectRulesClose() {
        this.setState({
            showDirectRules: false
        })
    }

    showIntermediariesRulesClose() {
        this.setState({
            showIntermediariesRules: false
        })
    }

    componentDidMount() {
        // this.setState({
        //     isLoading: true
        // });

        // const token = localStorage.getItem('accessKey');
        // if (token) {
        //     axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        // }
        // axios.post(CONFIG.API_URL + 'lookup/schedules')
        //     .then(res => {
        //         const scheduleData = res.data
        //         this.setState({
        //             isLoading: false,
        //             scheduleData
        //         });


        //     })
        //     .catch((err) => {
        //         this.setState({
        //             isLoading: false
        //         });

        //         if (err.response.status === 401) {
        //             localStorage.removeItem('accessKey');
        //             localStorage.removeItem('clientid');
        //             localStorage.removeItem('clientname');
        //             localStorage.removeItem('clientlogo');
        //             localStorage.removeItem('role');
        //             const { from } = this.props.location.state || { from: { pathname: "/" } };
        //             this.props.history.push(from);
        //         }
        //         else {
        //             alert(err.response.data);
        //         }

        //     });

    }

    render() {
        //document.title = 'AddShop';
        const { addshop
            // ,scheduleData
        } = this.state;
        const { showIntermediariesRules } = this.state.showIntermediariesRules;
        const { showDirectRules } = this.state.showDirectRules;

        return (
            <div className="AddShop">
                <Button onClick={this.addshopPopup} className="add-new-shop"><i>[+]</i>Add New Shop</Button>
                <Dialog
                    open={addshop}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="add-new-shop">
                    <Form onSubmit={(e) => this._handleSubmit(e)}>
                        <DialogTitle id="alert-dialog-title" className="title-popup">Add New Shop</DialogTitle>

                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">

                                <div className="previewComponent">
                                    <FormGroup className="shop-name">
                                        <label>Name of your Shop:</label>
                                        <Input type="text" name="shopname" id="shopname" maxLength="100" bsSize="lg" value={this.state.shopname} onChange={e => this.handleChange(e)} placeholder="Shop Name..." />
                                        <div className="clear"></div>
                                        <div className="errorMsg home-errormsg">{this.state.errors.shopname}</div>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Please Upload your schedule here by clicking on the upload icon:<br />Please upload only .xlsx file</label>
                                        <FilePond ref={ref => this.pond = ref}
                                            files={this.state.files}
                                            allowMultiple={false}
                                            allowFileTypeValidation={true}
                                            allowImagePreview={false}

                                            //data-max-file-size="1MB"
                                            allowFileSizeValidation={true}
                                            maxFileSize='1MB'
                                            maxTotalFileSize='1MB'
                                            labelMaxFileSizeExceeded='File is too large'
                                            labelMaxFileSize='Maximum file size is 1MB'
                                            //acceptedFileTypes={['file/xls', 'file/xl', 'file/xlsl']}
                                            labelIdle="Drag & Drop your Template OR Browse"
                                            //accept="*"
                                            // maxFiles={3}
                                            server={{
                                                url: CONFIG.API_URL + 'shop/upload',
                                                timeout: 7000,
                                                process: (fieldName, file, metadata, load, error, progress, abort, template) => {

                                                    // fieldName is the name of the input field
                                                    // file is the actual file object to send
                                                    const formData = new FormData();
                                                    formData.append('file', file, file.name);

                                                    const request = new XMLHttpRequest();
                                                    let token = localStorage.getItem('accessKey');
                                                    request.open('POST', CONFIG.API_URL + 'shop/upload/template');

                                                    // Should call the progress method to update the progress to 100% before calling load
                                                    // Setting computable to false switches the loading indicator to infinite mode
                                                    request.upload.onprogress = (e) => {
                                                        progress(e.lengthComputable, e.loaded, e.total);
                                                    };

                                                    // Should call the load method when done and pass the returned server file id
                                                    // this server file id is then used later on when reverting or restoring a file
                                                    // so your server knows which file to return without exposing that info to the client
                                                    request.onload = function () {
                                                        if (request.status >= 200 && request.status < 300) {
                                                            const template = request.responseText;
                                                            // var logoJson = JSON.parse(request.responseText);


                                                            localStorage.setItem('template', template);
                                                            //console.log(template);


                                                            // the load method accepts either a string (id) or an object
                                                            load(request.responseText);
                                                        }
                                                        else {
                                                            // Can call the error method if something is wrong, should exit after
                                                            error('oh no');
                                                        }
                                                    };
                                                    request.setRequestHeader('Authorization', 'Bearer ' + token);
                                                    request.send(formData);
                                                    // Should expose an abort method so the request can be cancelled
                                                    return {
                                                        abort: () => {
                                                            // This function is entered if the user has tapped the cancel button
                                                            request.abort();

                                                            // Let FilePond know the request has been cancelled
                                                            abort();
                                                        }
                                                    };
                                                }
                                            }
                                            }
                                            oninit={() => this.handleInit.bind(this)}
                                            onupdatefiles={(fileItems) => {
                                                // Set current file objects to this.state
                                                this.setState({
                                                    files: fileItems.map(fileItem => fileItem.file)
                                                });

                                            }}>
                                        </FilePond>
                                        <div className="clear"></div>
                                        <div className="errorMsg home-errormsg">{this.state.errors.template}</div>
                                        <p className="info">Import files must confirm the AI standard.<br />
                                            <Button onClick={this.showDirectRulesPopup} className="inline-btn">click here</Button> to know the file structure of Direct Upload Template.<br />
                                            <Button onClick={this.showIntermediariesRulesPopup} className="inline-btn">click here</Button> to know the file structure of Intermediaries Upload Template.</p>
                                    </FormGroup>
                                    {/* <FormGroup>
                                        <div className="selectdiv">
                                            <label>Select Schedule Pattern:</label>
                                            <Input placeholder="Schedule" value={this.state.schedule} onChange={e => this.handleChange(e)} type="select" name="schedule" id="schedule">
                                                <option value='null'>Select</option>
                                                <option value='0 0 * * *'>Daily @ 00:00</option>
                                                <option value='15 0 * * *'>Daily @ 00:15</option>
                                                <option value='30 0 * * *'>Daily @ 00:30</option>
                                                <option value='45 0 * * *'>Daily @ 00:45</option>
                                                <option value='0 1 * * *'>Daily @ 01:00</option>
                                                <option value='15 1 * * *'>Daily @ 01:15</option>
                                                <option value='30 1 * * *'>Daily @ 01:30</option>
                                                <option value='45 1 * * *'>Daily @ 01:45</option>
                                                <option value='0 2 * * *'>Daily @ 02:00</option>
                                                <option value='15 2 * * *'>Daily @ 02:15</option>
                                                <option value='30 2 * * *'>Daily @ 02:30</option>
                                                <option value='45 2 * * *'>Daily @ 02:45</option>
                                                <option value='0 3 * * *'>Daily @ 03.00</option>
                                                <option value='15 3 * * *'>Daily @ 03:15</option>
                                                <option value='30 3 * * *'>Daily @ 03:30</option>
                                                <option value='45 3 * * *'>Daily @ 03:45</option>
                                                <option value='0 4 * * *'>Daily @ 04.00</option>
                                                <option value='15 4 * * *'>Daily @ 04:15</option>
                                                <option value='30 4 * * *'>Daily @ 04:30</option>
                                                <option value='45 4 * * *'>Daily @ 04:45</option>
                                                <option value='0 5 * * *'>Daily @ 05.00</option>
                                                <option value='15 5 * * *'>Daily @ 05.15</option>
                                                <option value='30 5 * * *'>Daily @ 05.30</option>
                                                <option value='45 5 * * *'>Daily @ 05.45</option>
                                                <option value='0 6 * * *'>Daily @ 06.00</option>
                                                <option value='15 6 * * *'>Daily @ 06:15</option>
                                                <option value='30 6 * * *'>Daily @ 06:30</option>
                                                <option value='45 6 * * *'>Daily @ 06:45</option>
                                                <option value='0 7 * * *'>Daily @ 07.00</option>
                                                <option value='15 7 * * *'>Daily @ 07:15</option>
                                                <option value='30 7 * * *'>Daily @ 07:30</option>
                                                <option value='45 7 * * *'>Daily @ 07:45</option>
                                                <option value='0 8 * * *'>Daily @ 08.00</option>
                                                <option value='15 8 * * *'>Daily @ 08:15</option>
                                                <option value='30 8 * * *'>Daily @ 08:30</option>
                                                <option value='45 8 * * *'>Daily @ 08:45</option>
                                                <option value='0 9 * * *'>Daily @ 09:00</option>
                                                <option value='15 9 * * *'>Daily @ 09:15</option>
                                                <option value='30 9 * * *'>Daily @ 09:30</option>
                                                <option value='45 9 * * *'>Daily @ 09:45</option>
                                                <option value='0 10 * * *'>Daily @ 10:00</option>
                                                <option value='15 10 * * *'>Daily @ 10:15</option>
                                                <option value='30 10 * * *'>Daily @ 10:30</option>
                                                <option value='45 10 * * *'>Daily @ 10:45</option>
                                                <option value='0 11 * * *'>Daily @ 11:00</option>
                                                <option value='15 11 * * *'>Daily @ 11:15</option>
                                                <option value='30 11 * * *'>Daily @ 11:30</option>
                                                <option value='45 11 * * *'>Daily @ 11:45</option>
                                                <option value='0 12 * * *'>Daily @ 12:00</option>
                                                <option value='15 12 * * *'>Daily @ 12:15</option>
                                                <option value='30 12 * * *'>Daily @ 12:30</option>
                                                <option value='45 12 * * *'>Daily @ 12:45</option>
                                                <option value='0 13 * * *'>Daily @ 13:00</option>
                                                <option value='15 13 * * *'>Daily @ 13:15</option>
                                                <option value='30 13 * * *'>Daily @ 13:30</option>
                                                <option value='45 13 * * *'>Daily @ 13:45</option>
                                                <option value='0 14 * * *'>Daily @ 14:00</option>
                                                <option value='15 14 * * *'>Daily @ 14:15</option>
                                                <option value='30 14 * * *'>Daily @ 14:30</option>
                                                <option value='45 14 * * *'>Daily @ 14:45</option>
                                                <option value='0 15 * * *'>Daily @ 15:00</option>
                                                <option value='0 15 * * *'>Daily @ 15:15</option>
                                                <option value='30 15 * * *'>Daily @ 15:30</option>
                                                <option value='45 15 * * *'>Daily @ 15:45</option>
                                                <option value='0 16 * * *'>Daily @ 16:00</option>
                                                <option value='15 16 * * *'>Daily @ 16:15</option>
                                                <option value='30 16 * * *'>Daily @ 16:30</option>
                                                <option value='45 16 * * *'>Daily @ 16:45</option>
                                                <option value='0 17 * * *'>Daily @ 17:00</option>
                                                <option value='15 17 * * *'>Daily @ 17:15</option>
                                                <option value='30 17 * * *'>Daily @ 17:30</option>
                                                <option value='45 17 * * *'>Daily @ 17:45</option>
                                                <option value='0 18 * * *'>Daily @ 18:00</option>
                                                <option value='15 18 * * *'>Daily @ 18:15</option>
                                                <option value='30 18 * * *'>Daily @ 18:30</option>
                                                <option value='45 18 * * *'>Daily @ 18:45</option>
                                                <option value='0 19 * * *'>Daily @ 19:00</option>
                                                <option value='15 19 * * *'>Daily @ 19:15</option>
                                                <option value='30 19 * * *'>Daily @ 19:30</option>
                                                <option value='45 19 * * *'>Daily @ 19:45</option>
                                                <option value='0 20 * * *'>Daily @ 20:00</option>
                                                <option value='15 20 * * *'>Daily @ 20:15</option>
                                                <option value='30 20 * * *'>Daily @ 20:30</option>
                                                <option value='45 20 * * *'>Daily @ 20:45</option>
                                                <option value='0 21 * * *'>Daily @ 21:00</option>
                                                <option value='15 21 * * *'>Daily @ 21:15</option>
                                                <option value='30 21 * * *'>Daily @ 21:30</option>
                                                <option value='45 21 * * *'>Daily @ 21:45</option>
                                                <option value='0 22 * * *'>Daily @ 22:00</option>
                                                <option value='15 22 * * *'>Daily @ 22:15</option>
                                                <option value='30 22 * * *'>Daily @ 22:30</option>
                                                <option value='45 22 * * *'>Daily @ 22:45</option>
                                                <option value='0 23 * * *'>Daily @ 23:00</option>
                                                <option value='15 23 * * *'>Daily @ 23:15</option>
                                                <option value='30 23 * * *'>Daily @ 23:30</option>
                                                <option value='45 23 * * *'>Daily @ 23:45</option> */}
                                    {/* {scheduleData.map((lookup) => {
                                                    return (
                                                        <option value={lookup.value}>{lookup.name}</option>
                                                    );
                                                })} */}
                                    {/* </Input>
                                        </div>
                                        <div className="clear"></div>
                                        <div className="errorMsg home-errormsg">{this.state.errors.schedule}</div>
                                    </FormGroup> */}
                                </div>

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="submitButton"
                                type="submit"
                                onClick={(e) => this._handleSubmit(e)}><span>[+]</span>Add New</Button>
                            <Button onClick={this.addshopClose} color="primary" className="reset-btn">Cancel</Button>

                        </DialogActions>
                    </Form>
                </Dialog>

                {(this.state.showDirectRules) ?
                    <NewWindow title="Direct Upload Template Rules" center="Screen" onUnload={this.showDirectRulesClose}>
                        <div className="rules">
                            <h3>Direct Upload Template Rules</h3>
                            <ul>
                                <li>The first row must contain the column names</li>
                                <li>The column names must match those specified in the list below:
                                    <ol>
                                        <li>BatchID <span className="errorMsg">*</span></li>
                                        <li>ScheduleDate <span className="errorMsg">*</span></li>
                                        <li>cor</li>
                                        <li>lor <span className="errorMsg">*</span></li>
                                        <li>ratetype</li>
                                        <li>cob <span className="errorMsg">*</span></li>
                                        <li>adv <span className="errorMsg">*</span></li>
                                        <li>competitor <span className="errorMsg">*</span></li>
                                        <li>locgroup</li>
                                        <li>locations <span className="errorMsg">*</span></li>
                                        <li>pickuptime <span className="errorMsg">*</span></li>
                                        <li>dropofftime <span className="errorMsg">*</span></li>
                                    </ol>
                                </li>
                            </ul>
                            <p class="info">All the mandatory fields are marked with (<span className="errorMsg">*</span>).</p>
                        </div>
                    </NewWindow>
                    : ''}
                    {(this.state.showIntermediariesRules) ?
                    <NewWindow title="Intermediaries Upload Template Rules" center="Screen" onUnload={this.showIntermediariesRulesClose}>
                        <div className="rules">
                            <h3>Intermediaries Upload Template Rules</h3>
                            <ul>
                                <li>The first row must contain the column names</li>
                                <li>The column names must match those specified in the list below:
                                    <ol>
                                        <li>BatchID <span className="errorMsg">*</span></li>
                                        <li>ScheduleDate <span className="errorMsg">*</span></li>
                                        <li>cor</li>
                                        <li>lor <span className="errorMsg">*</span></li>
                                        <li>ratetype</li>
                                        <li>cob <span className="errorMsg">*</span></li>
                                        <li>adv <span className="errorMsg">*</span></li>
                                        <li>competitor <span className="errorMsg">*</span></li>
                                        <li>locgroup</li>
                                        <li>locations <span className="errorMsg">*</span></li>
                                        <li>pickuptime <span className="errorMsg">*</span></li>
                                        <li>dropofftime <span className="errorMsg">*</span></li>
                                        <li>suppliercodes</li>
                                        <li>minimumpricefile</li>
                                        <li>radius</li>
                                    </ol>
                                </li>
                            </ul>
                            <p class="info">All the mandatory fields are marked with (<span className="errorMsg">*</span>).</p>
                        </div>
                    </NewWindow>
                    : ''}
            </div>

        );
    }
}


export default AddShop;