import React, { Component } from 'react';
import './MyAccount.css';
import { Container, Row, Col, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Sidebar } from '../../Core';
import { CONFIG } from '../../utils/config';
import axios from 'axios';

class MyAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            username: '',
            password: '',
            errors: [],
            passworderror: '',
            passwordSuccess: ''
        }
        //this.reset = this.reset.bind(this);
    }

    reset(event) {
        this.form.reset();
        this.setState({ passwordSuccess: '', passworderror: '', password: '', errors: [] });
    }

    handleSubmit(event, errors, values) {
        debugger;
        if (errors.length > 0) {
            console.log(errors);
        }
        else {
            this.setState({ isLoading: true });
            var data = {
                password: values.password
            };
            axios.post(CONFIG.API_URL + 'account/changepassword', data)
                .then(res => {
                    this.form.reset();
                    this.setState({ passwordSuccess: "The password changed successfully", isLoading: false });

                })
                .catch(err => {
                    this.setState({ isLoading: false });
                    if (err.response.status === 400) {
                        const passworderror = err.response.data;
                        this.setState({ passworderror });
                    }
                });
        }
        this.setState({ errors, values });
    }

    componentDidMount() {

    }
    render() {
        //const classes = useStyles();
        const { isLoading, passworderror, passwordSuccess } = this.state;
        document.title = 'Change Password';
        return (
            <div className="homepage" >
                <Container className="body-content">
                    <Row>
                        <Col md={3}>
                            <Sidebar parentMethod={this.handler} parm={this.props} />
                        </Col>
                        <Col md={9} className="error-msg-position">
                            {isLoading ?
                                <div className="loading"></div>
                                : ''}

                            <h4 className="graph-view">Change Password</h4>
                            {passwordSuccess && passwordSuccess != '' ?
                                <p className="success-msg">
                                    {passwordSuccess}
                                </p>
                                : ''
                            }
                            <AvForm onSubmit={this.handleSubmit.bind(this)} onReset={this.reset.bind(this)} ref={c => (this.form = c)}>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup className="shop-name">
                                            <AvField name="password" label="New Password:" placeholder="******" type="password" validate={{
                                                required: { value: true, errorMessage: 'Enter the new password' },
                                                pattern: { value: '^(.{0,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{4,})|(.{1,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{3,})|(.{2,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{2,})|(.{3,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{1,})|(.{4,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{0,})$', errorMessage: 'Passwords are case-sensitive, must be between 6 to 25 characters and contain at least 1 letter and 1 number or special character.' },
                                                minLength: { value: 6, errorMessage: 'Your Password must be 6 characters' },
                                                maxLength: { value: 25, errorMessage: 'Your Password must be 25 characters' }
                                            }} />
                                            <div className="clear"></div>
                                            <div className="errorMsg home-errormsg">{this.state.errors.password}</div>
                                        </FormGroup>
                                    </Col>
                                </Row><Row>
                                    <Col md={4}>
                                        <FormGroup className="shop-name">
                                            <AvField name="ConfirmPassword" label="Retype New Password:" placeholder="******" type="password" validate={{
                                                required: { value: true, errorMessage: 'Retype your new password' },
                                                match: { value: 'password', errorMessage: 'Password mismatched' }
                                            }} />
                                            <div className="clear"></div>
                                            <div className="errorMsg home-errormsg">{this.state.errors.password}</div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div>
                                    <Row className="right-side-button button-align">
                                    <Col md={4}>
                                            <Button color="primary" type="submit" className="btn-changepass">Change Password</Button>
                                            <Button color="primary" type="reset" className="cancel-btn-pass button-cancel">Cancel</Button>
                                            <span className="sign-error">{passworderror}</span>
                                        </Col>
                                    </Row>
                                </div>

                            </AvForm>
                            <div className="clearfix"></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
export default MyAccount;