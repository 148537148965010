import React, { Component } from 'react';
import './Sidebar.css';
import { Button, Form, FormGroup, Input, Row, Col } from 'reactstrap';
import axios from 'axios';
import moment from 'moment';
import { CONFIG } from '../../utils/config';
import news from './../../Assets/Img/news.png';
import time from './../../Assets/Img/time.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import bell from './../../Assets/Img/bell.png';
class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            statusData: [],
            status: null,
            batchid: '',
            runDate: '',
            errors: {},
            isLoading: false

        };
        this.searchClick = this.searchClick.bind(this);
        this.cancelCourse = this.cancelCourse.bind(this);
        this.datehandleChange = this.datehandleChange.bind(this);

    }
    datehandleChange(date) {
        debugger;
        this.setState({
            runDate: date
        });
      }
    componentDidMount() {
        this.setState({
            isLoading: true
        });
        const token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'lookup/status')
            .then(res => {
                const statusData = res.data
                this.setState({
                    isLoading: false,
                    statusData
                });


            })
            .catch((err) => {
                this.setState({
                    isLoading: false
                });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    localStorage.removeItem('clientid');
                    localStorage.removeItem('clientname');
                    localStorage.removeItem('clientlogo');
                    localStorage.removeItem('role');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }

            });
        axios.post(CONFIG.API_URL + 'home/announcements')
            .then(res => {
                const announcementsData = res.data
                this.setState({
                    isLoading: false,
                    announcementsData
                });


            })
            .catch((err) => {
                this.setState({
                    isLoading: false
                });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    localStorage.removeItem('clientid');
                    localStorage.removeItem('clientname');
                    localStorage.removeItem('clientlogo');
                    localStorage.removeItem('role');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            })

    }




    searchClick(e) {
        localStorage.setItem('name', this.state.batchid ? this.state.batchid : null);
        localStorage.setItem('status', this.state.status ? this.state.status : null);
        localStorage.setItem('rundate', this.state.runDate ? this.state.runDate : null);

        e.preventDefault();
        if (this.validateForm()) {
            let fields = {};
            let fields1 = {};
            fields = "";
            fields1 = "";
            this.setState({ fields: fields, fields1: fields1 });
            // this.props.parm.children.props.location({
            //     pathname: `/searchresults`,
            //     data: data // your data array of objects
            // });
            let path = `search-results`;
//debugger;
            this.props.parm.history.push(path);

            var isSearchPage = this.props.parm.location.pathname, expr = /search-results/;
            if (isSearchPage.match(expr)) {
                this.props.parentMethod();
                //window.location.reload();
            }
        }


    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    validateForm() {
        let fields = this.state.batchid;
        let fields1 = this.state.status;
        let errors = {};
        let formIsValid = true;

        if (!fields && (fields1 === null)) {
            formIsValid = false;
            errors["batchid"] = "* Please enter your Batch Id";
        }
        if (!fields && fields1 === null) {
            formIsValid = false;
            errors["status"] = "* Please Choose Status";
        }

        this.setState({
            errors: errors
        });
        return formIsValid;
    }

    //reset function
    cancelCourse = () => {        
        this.setState({
            status: null, batchid :'', runDate: '', errors: {}
        })
    }

    render() {
        const { statusData, announcementsData, isLoading } = this.state;
        //console.log(announcementsData);
        return (
            <div className="Sidebar">
            {isLoading ?
                    <div className="loading"></div>
                    : ''
                }

                <Row>
                    <Col>

                        <Form className="form-box search-form" id="resetall" onSubmit={this.submit}>
                            <h4>Search for Shop</h4>
                            <Row form >
                                <Col md={12}>
                                    <FormGroup>
                                        <Input type="text" name="batchid" id="batchid" maxLength="100" bsSize="lg" value={this.state.batchid} onChange={e => this.handleChange(e)} placeholder="Batch Id..." />
                                        <div className="errorMsg home-errormsg">{this.state.errors.batchid}</div>
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <div className="selectdiv">
                                            <Input placeholder="Status" value={this.state.status} onChange={e => this.handleChange(e)} type="select" name="status" id="status">
                                            <option disabled selected={this.state.status === null}>Status</option>
                                                {statusData.map((lookup) => {
                                                    return (
                                                        <option value={lookup.value}>{lookup.name}</option>
                                                    );
                                                })}
                                            </Input>
                                        </div>
                                        <div className="errorMsg home-errormsg">{this.state.errors.status}</div>
                                    </FormGroup>
                                </Col>
                                <Col md={12}>

                                    <FormGroup>
                                        {/* <Input type="date" name="runDate" id="runDate" placeholder="Run Date : " value={this.state.runDate}  onChange={this.className=(this.value!=''?'has-value':'')} onChange={e => this.handleChange(e)} /> */}
                                        <DatePicker
        selected={this.state.runDate}
        onChange={this.datehandleChange}
        placeholderText="Run Date"
        dateFormat="P"
      />
                                    </FormGroup>
                                </Col>

                                <Col md={12} className="btn-top">
                                    <Button type='submit' className="search-btn" color="danger" size="lg" onClick={this.searchClick}>Search</Button>
                                    <Button type="button" className="reset-btn" onClick={this.cancelCourse}>Reset</Button>
                                </Col>
                            </Row>
                        </Form>
                        <div className="avis-announcements">
                            <h4>Announcements</h4>
                            <div className="scrool-side">
                            {announcementsData && announcementsData.length > 0 ?
                                announcementsData.map((announcement) =>
                                    announcement.isactive ? <div className="mrg-btm-15">
                                        <h5><img src={news} alt="news" />{announcement.title}</h5>
                                        <ul>
                                            <li>{announcement.description}</li>
                                            <li className="date-time"><img src={time} alt="date and time" />{announcement.modifieddate ? moment(announcement.modifieddate).format("L, LT") : moment(announcement.createddate).format("L, LT")}</li>
                                        </ul>
                                    </div>
                                        : ''
                                )
                                : 'No announcements found!'}
                                </div>
                            {/* <div className="anomaly-alert">
                                <h5>Pricing Anomaly Alert</h5>
                                <div className="repeat-loop">
                                    <h6><img src={bell} alt="bell" />Hertz LAS Price increase</h6>
                                    <ul>
                                        <li>20% for Economy Car</li>
                                    </ul>
                                        </div>
                        </div> */}

                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Sidebar;