import React, { Component } from 'react';
import './Home.css';
import axios from 'axios';
import moment from 'moment';
import { CONFIG } from '../../utils/config';
import { BarChart } from 'react-charts-d3';
import { Container, Row, Col } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { AddShop } from '../AddShop';
import { Sidebar } from '../../Core';
import * as d3 from "d3";
class Home extends Component {


    constructor(props) {
        super(props);

        this.state = {
            graphData: [],
            sourceData: [],
            summaryData: '',
            isLoading: false,
            tooltipOpen: false,
            statusId: null
        };
        this.tooltipToggle = this.tooltipToggle.bind(this);

    }
    tooltipToggle(id) {
        debugger;
        let statusId = id;
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
            statusId
        });
    }
    componentDidMount() {
        this.setState({
            isLoading: true
        });
        const token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'home/sources')
            .then(res => {
                const sourceData = res.data
                this.setState({
                    isLoading: false,
                    sourceData
                });


            })
            .catch((err) => {
                this.setState({
                    isLoading: false
                });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    localStorage.removeItem('clientid');
                    localStorage.removeItem('clientname');
                    localStorage.removeItem('clientlogo');
                    localStorage.removeItem('role');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }

            });
        axios.post(CONFIG.API_URL + 'home')
            .then(res => {
                const summaryData = res.data
                const graphData = res.data.graph;
                this.setState({
                    isLoading: false,
                    summaryData,
                    graphData
                });
                this.dashboardBarChart1(graphData);

            })
            .catch((err) => {
                this.setState({
                    isLoading: false
                });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    localStorage.removeItem('clientid');
                    localStorage.removeItem('clientname');
                    localStorage.removeItem('clientlogo');
                    localStorage.removeItem('role');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }

            });


    }
    dashboardBarChart1(graphdata) {



        // set the dimensions and margins of the graph
        var margin = { top: 20, right: 20, bottom: 70, left: 50 },
            width = 940 - margin.left - margin.right,
            height = 500 - margin.top - margin.bottom;

        // set the ranges
        var x = d3.scaleBand()
            .range([0, width])
            .padding(0.1);
        var y = d3.scaleLinear()
            .range([height, 0]);

        // append the svg object to the body of the page
        // append a 'group' element to 'svg'
        // moves the 'group' element to the top left margin
        var svg = d3.select(".barchart").append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");
                

        // get the data
        var tooltip = d3.select(".barchart").append("div").attr("class", "toolTip graph-tooltip");
        var data = graphdata[0].values;
        var data1 =graphdata[0].key
            // format the data
            data.forEach(function (d) {
                d.y = +d.y;
            });

            // Scale the range of the data in the domains
            x.domain(data.map(function (d) { return d.x; }));
            y.domain([0, d3.max(data, function (d) { return d.y; })]);
            svg.append("rect").attr("cx",20).attr("cy",1).attr("r", 6).style("fill", "#4682b4").attr("width", 15).attr("height", 15).attr('transform', "translate(" + (2) + "," + (-20) + ")") 
            svg.append("text").attr("x", 25).attr("y", -11).text(data1).style("font-size", "15px").attr("alignment-baseline","middle")
            var formatComma = d3.format(",")
            // append the rectangles for the bar chart
            svg.selectAll(".bar")
                .data(data)
                .enter().append("rect")
                .style("fill", "steelblue")
                .attr("class", "bar")
                .attr("x", function (d) { return x(d.x); })
                .attr("width", x.bandwidth())
                .attr("y", function (d) { return y(d.y); })
                .attr("height", function (d) { return height - y(d.y); })
                .on("mousemove", function(d){
                    tooltip
                    .style("left", d3.event.pageX - 400 + "px")
                    .style("top", d3.event.pageY - 180 + "px")
                      .style("display", "inline-block")
                      //.style('fill', 'black')
                      .html((d.x) + "<br>"  + formatComma(d.y));
                })
                    .on("mouseout", function(d){ tooltip.style("display", "none");});

            // add the x Axis
            svg.append("g")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x))
                .selectAll("text")
              .style("text-anchor", "end")
              .attr("dx", "-.8em")
              .attr("dy", "-.55em")
              .attr("transform", "rotate(-90)" );

            // add the y Axis
            svg.append("g")
                .call(d3.axisLeft(y));

      
    }
  
    render() {
        const { sourceData, summaryData, graphData, isLoading } = this.state;
        // const graph = summaryData.graph && summaryData.graph.length;
        //console.log(graphData);
        document.title = (localStorage.getItem('clientname')) ? localStorage.getItem('clientname') + '- Client Dashboard' : 'Client Dashboard';

        return (
            <div className="homepage">
                {isLoading ?
                    <div className="loading"></div>
                    : ''
                }
                <Container className="body-content">
                    <Row>
                        <Col md={3}>
                            <Sidebar parentMethod={this.handler} parm={this.props} />
                        </Col>
                        <Col md={9}>
                            <h4 className="graph-view">Graph View <AddShop parentMethod={this.handler1} parm={this.props} /></h4>
                            <Container>
                                <Row>
                                    <Col md={4} className="no-padding set-background">
                                        <div className="summary">
                                            <h4>Summary</h4>
                                            <div className="display-value">{summaryData ? summaryData.summary.callsummary : 'N/A'}</div>
                                            <div className="cost-plan">
                                                <div className="cost">
                                                    <label>Period Cost</label>
                                                    <div className="tbd">${summaryData ? summaryData.summary.periodcast : 'N/A'}</div>
                                                </div>
                                                <div className="plan">
                                                    <label>Current Rate Plan</label>
                                                    <div className="tbd">{summaryData ? summaryData.summary.currentrateplan : 'N/A'}</div>
                                                </div>
                                                <div className="clear"></div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={8} className="padding-right">
                                        <div className="health-sources">
                                            <h4>Source Health Status</h4>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Source</TableCell>
                                                        <TableCell align="center">Status</TableCell>
                                                        <TableCell>Last Updated</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {sourceData ? sourceData.map(source => (
                                                        <TableRow key={source.sourceid}>
                                                            <TableCell component="th" scope="row">
                                                                {source.sourcename}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {source.status === "Latency Issue" ? <div title={source.status} className="pending"></div> : ''}
                                                                {source.status === "No Issues" ? <div title={source.status} className="success"></div> : ''}
                                                                {source.status === "Failed" ? <div title={source.status}
                                                                    //id={'Tooltip-' + source.sourceid}
                                                                    className="failed"></div> : ''}
                                                                {/* <Tooltip placement="top" isOpen={statusId === source.sourceid ? this.state.tooltipOpen: false}  target={'Tooltip-' + source.sourceid} toggle={() => this.tooltipToggle(source.sourceid)}>
                                                                    {source.status}
                                                                </Tooltip> */}
                                                            </TableCell>
                                                            <TableCell>{source.modifieddate ? moment(source.modifieddate).format("L, LT") : moment(source.createddate).format("L, LT")}</TableCell>
                                                        </TableRow>
                                                    ))
                                                        : <TableRow>
                                                            <TableCell colspan='3'>There is no source health found.</TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </Col></Row>
                            </Container>

                            {graphData.length > 0 ?
                                <div class="bar-chart-view barchart">
                                    {/* <BarChart data={graphData} axisConfig={{
                                        showXAxis: true,
                                        showXAxisLabel: true,
                                        xLabel: '',
                                        xLabelPosition: 'right',
                                        showYAxis: true,
                                        showYAxisLabel: true,
                                        yLabel: '',
                                        yLabelPosition: 'top',
                                    }}
                                        noDataMessage={'There is no graph view data'}
                                        margin={{ top: 20, left: 60, bottom: 20, right: 20 }}
                                        height={400} colorSchemeCategory={['#007bff']} /> */}
                                </div>
                                : <p className="no-data-style1">There is no graph view data</p>}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}


export default Home;