import React, { Component } from 'react';
import './Footer.css';
import { Container } from 'reactstrap';
class Footer extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <div className="footer">
                <Container>
                © Copyright 2019 Aggregate Intelligence - All Rights Reserved
                </Container>
            </div>
        );
    }
}

export default Footer;