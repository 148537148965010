import React, { Component } from 'react';
import './Login.css';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Button } from 'reactstrap';
import axios from 'axios';
import { CONFIG } from '../../utils/config';
class Login extends Component {
    constructor(props) {
        super(props);

        this.loginSubmit = this.loginSubmit.bind(this);
        this.state = {
            isLoading: false
        };

    }
    loginSubmit(event, errors, values) {
        
        if (errors.length > 0) {

            console.log(errors);
        }
        else {
            this.setState({ isLoading: true });
            axios.post(CONFIG.API_URL + 'account/login', values)
                .then(res => {
                    const getTokvalues = res.data;
                    //console.log(getTokvalues);
                    this.setState({ isLoading: false , getTokvalues});
                    axios.defaults.headers.common["Authorization"] = 'Bearer ' + getTokvalues.token;
                    localStorage.setItem('accessKey', getTokvalues.token);
                    localStorage.setItem('clientid', getTokvalues.clientid);
                    localStorage.setItem('clientname', getTokvalues.clientname);
                    localStorage.setItem('clientlogo', getTokvalues.clientlogo);
                    localStorage.setItem('role', getTokvalues.role);
                    const { from } = this.props.location.state || { from: { pathname: "/dashboard" } };
                    this.props.history.push(from);
                   // this.form && this.form.reset();

                })
                .catch(err => {
                    this.setState({ isLoading: false });
                    if (err.response.status === 400) {
                        // this.setState({ isLoading: true });
                        const sighinerror = err.response.data;
                        // let errorMessage = err.response.data;
                        this.setState({ sighinerror });

                        //this.form && this.form.reset();

                        // const { from } = this.props.location.state || { from: { pathname: "/sign-in" } };
                        // this.props.history.push(from);
                        // this.setState({ isLoading: false });
                    }

                });

        }

        this.setState({ errors, values });
    }

    componentDidMount() {

        window.scrollTo(0, 0);
    }

    render() {
        document.title = 'Login';
        const { sighinerror, isLoading } = this.state;
        return (
            <div className="login">
            <h4>Log <span>in</span></h4>
            <p>Please enter your username and password to login. </p>
            {isLoading ? <div className="loading center"></div> : ''}
                <AvForm onSubmit={this.loginSubmit.bind(this)}>
                    <AvField name="email" label="Email" type="email" validate={{ required: true }} />
                    <AvField name="password" label="Password" type="password" validate={{ required: true }} />
                    <Button type="submit" color="primary" className="btn-submit">Submit</Button>
                    <span className="sign-error">{sighinerror}</span>
                </AvForm>

            </div>
        );
    }
}


export default Login;