import React, { Component } from 'react';
import { Header, Footer } from '../../Core';
import './LoginLayout.css';
import { Container, Row, Col } from 'reactstrap';
class LoginLayout extends Component {
    render() {
        return (
            <div className="Login-page">

                <Header parm={this.props} />
                <Container className="body-content">
                    <Row>
                        <Col md={12}>
                            {this.props.children}
                        </Col>
                    </Row>
                </Container>
                <Footer />

            </div>
        );
    }
}

export default LoginLayout;