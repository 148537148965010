import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Home, Schedules, MyShop, SearchResults, NotFound, Login, Announcements, MyAccount  } from '../Views';
import { HomeLayoutRoute, LoginLayoutRoute } from './routeLayout';

class RouteComp extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    {/* Default router - Home Page */}
                    <LoginLayoutRoute exact path="/" component={Login} />
                    <HomeLayoutRoute path="/dashboard" component={Home} />
                    <HomeLayoutRoute path="/schedules" component={Schedules} />
                    <HomeLayoutRoute path="/myshop" component={MyShop} />
                    <HomeLayoutRoute path="/changepassword" component={MyAccount} />
                    <HomeLayoutRoute path="/search-results" component={SearchResults} />
                    
                    {/* {(localStorage.getItem('role') === 'Admin') ? */}
                    <LoginLayoutRoute path="/announcements" component={Announcements} />
                    {/* : '' } */}


                    

                  

                    {/* 404 page found */}
                    <HomeLayoutRoute path="*" component={NotFound} status={404} />

                </Switch>
            </BrowserRouter>
        );
    }
}
export default RouteComp;