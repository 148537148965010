import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Header.css';
import { Button } from 'reactstrap';
//import logo from './../../Assets/Img/logo.png';
import signin from './../../Assets/Img/sign-in.png';
import signout from './../../Assets/Img/logout.png';
//import { CONFIG } from '../../utils/config';
import { Container } from 'reactstrap';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from 'reactstrap';

var images = require.context('./../../Assets/Logos', true);

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  sigOut() {
    localStorage.removeItem('accessKey');
    localStorage.removeItem('clientid');
    localStorage.removeItem('clientname');
    localStorage.removeItem('clientlogo');
    localStorage.removeItem('role');
    localStorage.removeItem('name');
    localStorage.removeItem('status');
    localStorage.removeItem('rundate');
    localStorage.removeItem('accesstoken');
    localStorage.removeItem('shoppage');
    localStorage.removeItem('searchpaginationpage');
    localStorage.removeItem('schedpaginationpage');
    localStorage.removeItem('annpaginationpage');
    
    
    

    this.props.parm.children.props.history.push({
      pathname: `/`
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    let img_src = !localStorage.getItem('clientlogo') || localStorage.length === 0 ? '' : images(`./${localStorage.getItem('clientlogo')}`);

    return (

      <div className="header">
        <Container>
          <Navbar expand="md">

            <NavbarBrand>
              <Link to="/dashboard" >

                {(localStorage.getItem('clientlogo') === 'null') ? '' : <img src={img_src} className="logo" alt="" />}
              </Link></NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                {(localStorage.getItem('accessKey')) ?
                  <NavItem>
                    <Link to="/dashboard" >Dashboard</Link>
                  </NavItem>
                  : ''}
                {(localStorage.getItem('accessKey')) ?
                  <NavItem>
                    <Link to="/schedules" >My Schedules</Link>
                  </NavItem>
                  : ''}
                {(localStorage.getItem('role') === 'Admin') ?
                  <NavItem>
                    <Link to="/announcements" >Announcements</Link>
                  </NavItem>
                  : ''}
                {(localStorage.getItem('accessKey')) ?
                  <NavItem>
                    <Link to="/myshop" >My Shop</Link>
                  </NavItem>
                  : ''}
                  {(localStorage.getItem('accessKey')) ?
                  <NavItem>
                    <Link to="/changepassword" >Change Password</Link>
                  </NavItem>
                  : ''}
                {(!localStorage.getItem('accessKey')) ?
                  <NavItem>
                    <Link to="/" className="Sign-in-btn"><img src={signin} alt="sign in" />Sign In</Link>
                  </NavItem>
                  : (localStorage.getItem('accessKey')) ?
                    <Button onClick={this.sigOut.bind(this)} className="Sign-out-btn"><img src={signout} alt="sign in" />Sign out</Button>

                    : ''}


              </Nav>
            </Collapse>
          </Navbar>
        </Container>
      </div>
    );
  }
}

export default Header;