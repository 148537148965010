import React, { Component } from 'react';
import './MyShop.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button } from 'reactstrap';
import Switch from "react-switch";
import axios from 'axios';
import moment from 'moment';
import { CONFIG } from '../../utils/config';
import delete1 from './../../Assets/Img/delete.png';
import restore from './../../Assets/Img/restore.png';
import Pagination from "react-js-pagination";
import { AddShop } from '../AddShop';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import FastForward from '@material-ui/icons/FastForward';
import FastRewind from '@material-ui/icons/FastRewind';
import { Container, Row, Col } from 'reactstrap';
import { Sidebar } from '../../Core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { UpdateTemplate } from '../UpdateTemplate';
import TimeInput from 'material-ui-time-picker';

class MyShop extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: false,
            myshopData: [],
            activePage: 1, // (!localStorage.getItem('shoppage') || (localStorage.getItem('shoppage') === 'null')) ? 1 : localStorage.getItem('shoppage'),
            isLoading: false,
            open: false,
            scheduleid: null,
            schedId: null,
            templateItems: [],
            cor: '',
            cob: '',
            location: '',
            competitor: '',
            selectedShops: [],
            checkAll: false,
            batchid: '',
            checkEntire: false,
            scheduleday: '',
            scheduletime: '',
            shopname: ''
        };
        localStorage.setItem('shoppage', 1);
        this.setSelectedStorage();
        this.handleChange = this.handleChange.bind(this);
        this.updateShopClick = this.updateShopClick.bind(this);
        this.deleteShopClick = this.deleteShopClick.bind(this);
        this.handler1 = this.handler1.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleMultipleShops = this.handleMultipleShops.bind(this);
        this.deleteMultipleShops = this.deleteMultipleShops.bind(this);
        this.handleShopCheck = this.handleShopCheck.bind(this);
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheckEntireShops = this.handleCheckEntireShops.bind(this);
        this.reset = this.reset.bind(this);
        this.getSelectedSchedules = this.getSelectedSchedules.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
    }

    handleMultipleShops(event) {
        if (this.state.selectedShops) {
            this.setState({
                isLoading: true
            });

            var status = false;//event.currentTarget.checked;

            let token = localStorage.getItem('accessKey');
            if (token) {
                axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }
            var data = {
                ids: this.state.selectedShops.join(','),
                isactive: status
            };
            axios.post(CONFIG.API_URL + 'shop/schedules/status', data)
                .then(res => {
                    this.setState({ selectedShops: [] }, () => {
                        this.setSelectedStorage();
                        this.initialLoad(false);
                    });
                })

                .catch((err) => {
                    this.setState({
                        isLoading: false
                    });
                    alert(err);
                });
        }
    }

    deleteMultipleShops() {
        this.setState({
            isLoading: true
        });
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        var data = { ids: this.state.selectedShops.join(',') };
        axios.post(CONFIG.API_URL + 'shop/schedules/delete', data)
            .then(res => {
                this.setState({ selectedShops: [] }, () => {
                    this.setSelectedStorage();
                    this.initialLoad(false);
                });

            })

            .catch((err) => {
                this.setState({
                    isLoading: false
                });
                alert(err);
            });
    }

    handleCheckAll(event) {
        var selectedShops = [];

        if (event.currentTarget.checked) {
            this.state.myshopData.myshops.map((s) => {
                selectedShops.push(s.scheduleid);
            });
        }
        this.setState({ selectedShops: selectedShops, checkAll: event.currentTarget.checked }, () => {
            this.setSelectedStorage();
        });

    }
    handleCheckEntireShops(event) {
        if (event.currentTarget.checked) {
            this.setState({ isLoading: true });
            const data = {
                page: 1, //(!localStorage.getItem('shoppage') || (localStorage.getItem('shoppage') === 'null')) ? 1 : localStorage.getItem('shoppage'),
                pagelength: 50,
                countonly: true
            }
            this.apidata_call(data);
        }
        else {
            var selectedShops = [];
            this.setState({ selectedShops: selectedShops, checkEntire: event.currentTarget.checked }, () => {
                this.setSelectedStorage();
            });
        }

    }

    handleTimeChange(time) {
        this.setState({ scheduletime: time.toTimeString().split(' ')[0].substr(0, 5) });
    }

    handleShopCheck(event, id) {
        var selectedShops = this.state.selectedShops;
        if (event.currentTarget.checked) {
            selectedShops.push(id);

        }
        else {
            selectedShops.splice(selectedShops.indexOf(id), 1);
            this.setState({ checkAll: false, checkEntire: false }, () => {

            });
        }
        this.setSelectedStorage();
    }

    setSelectedStorage() {
        var selectedShops = this.state.selectedShops;
        if (selectedShops.length == 0) {
            localStorage.removeItem('selectedShops');
        }
        localStorage.setItem('selectedShops', JSON.stringify(selectedShops));
        this.setState({ selectedShops });
    }

    handleClickOpen(scheduleid) {
        this.setState({
            open: true,
            scheduleid: scheduleid
        });
    }

    handleClose() {
        this.setState({
            open: false
        });
    }
    updateShopClick(scheduleid) {
        this.setState({
            isLoading: true
        });

        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.put(CONFIG.API_URL + 'shop/schedule/restore/' + scheduleid)
            .then(res => {

                //this.componentDidMount();
                const data = {
                    page: this.state.activePage, //(!localStorage.getItem('shoppage') || (localStorage.getItem('shoppage') === 'null')) ? 1 : localStorage.getItem('shoppage'),
                    pagelength: 50,
                    countonly: false
                }
                this.apidata_call(data);
            })

            .catch((err) => {

                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    localStorage.removeItem('clientid');
                    localStorage.removeItem('clientname');
                    localStorage.removeItem('clientlogo');
                    localStorage.removeItem('role');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }



            });

    }

    deleteShopClick() {
        this.setState({
            isLoading: true
        });
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        axios.request({
            url: CONFIG.API_URL + 'shop/schedule/' + this.state.scheduleid,
            method: 'delete',
        })
            .then(res => {
                this.setState({ scheduleid: '', open: false })
                // this.componentDidMount();
                const data = {
                    page: this.state.activePage, //(!localStorage.getItem('shoppage') || (localStorage.getItem('shoppage') === 'null')) ? 1 : localStorage.getItem('shoppage'),
                    pagelength: 50,
                    countonly: false
                }
                this.apidata_call(data);

            })

            .catch((err) => {
                this.setState({
                    isLoading: false
                });
                alert(err);


            });


    }
    handleChange(scheduleid, status) {
        this.setState({
            isLoading: true
        });
        if (status === 'Inactive') {
            status = true
        }
        else if (status === 'Active') {
            status = false
        }
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.put(CONFIG.API_URL + 'shop/schedule/status/' + scheduleid + '/' + status)
            .then(res => {

                //this.componentDidMount();
                const data = {
                    page: this.state.activePage, //(!localStorage.getItem('shoppage') || (localStorage.getItem('shoppage') === 'null')) ? 1 : localStorage.getItem('shoppage'),
                    pagelength: 50,
                    countonly: false
                }
                this.apidata_call(data);
            })

            .catch((err) => {

                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    localStorage.removeItem('clientid');
                    localStorage.removeItem('clientname');
                    localStorage.removeItem('clientlogo');
                    localStorage.removeItem('role');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }



            });
        // this.setState({ checked });

    }
    handlePageChange(pageNumber) {
        window.scrollTo(0, 0);
        localStorage.setItem('shoppage', pageNumber ? pageNumber : null);
        this.setState({ activePage: pageNumber, isLoading: true, checkAll: false });
        const data = {
            page: pageNumber,
            pagelength: 50,
            countonly: false
        }
        data.Page = pageNumber;

        this.apidata_call(data);
    }

    loadTemplateItems(initialCall) {
        const token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        axios.get(CONFIG.API_URL + 'lookup/templateitems/' + localStorage.getItem('clientid'))
            .then(res => {
                const templateItems = res.data
                console.log(templateItems);
                this.setState({
                    //isLoading: false,
                    templateItems,
                    activePage: initialCall ? 1 : this.state.activePage,
                });

            })
            .catch((err) => {
                if (err.response.status === 401) {
                    console.log(err.response.data);
                }
                else {
                    alert(err.response.data);
                }

            });
    }
    apidata_call(data) {
        debugger;
        data.cor = this.state.cor;
        data.cob = this.state.cob;
        data.batchid = this.state.batchid != null ? this.state.batchid.trim() : this.state.batchid;
        data.location = this.state.location;
        data.competitor = this.state.competitor;
        data.shopname = this.state.shopname;
        data.scheduleday = this.state.scheduleday;
        data.scheduletime = this.state.scheduletime;

        const token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        axios.post(CONFIG.API_URL + 'shop/mylist', data)
            .then(res => {
                if (data.countonly) {
                    var selectedShops = [];
                    selectedShops = res.data.ids;
                    this.setState({ selectedShops: selectedShops, isLoading: false, checkEntire: true, tooltipOpen: false }, () => {
                        this.setSelectedStorage();
                    });
                }
                else {
                    const myshopData = res.data
                    console.log(myshopData);
                    this.setState({
                        isLoading: false,
                        myshopData,
                        activePage: myshopData.pagination.page,
                        tooltipOpen: false
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    isLoading: false
                });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    localStorage.removeItem('clientid');
                    localStorage.removeItem('clientname');
                    localStorage.removeItem('clientlogo');
                    localStorage.removeItem('role');
                    localStorage.removeItem('selectedShops');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    if (err.response.data.indexOf('incorrect syntax')) {
                        alert("Please enter a valid Batch Id");
                    }
                    else
                        alert(err.response.data);
                }

            });
    }
    handler1() {
        this.componentDidMount();
    }
    componentDidMount() {
        this.initialLoad(true);
    }

    initialLoad(initial) {
        debugger;
        if (localStorage.getItem('selectedShops')) {
            this.setState({ selectedShops: JSON.parse(localStorage.getItem('selectedShops')) });
        }

        this.setState({
            isLoading: true
        }, () => {
            const data = {
                page: this.state.activePage, //(!localStorage.getItem('shoppage') || (localStorage.getItem('shoppage') === 'null')) ? 1 : localStorage.getItem('shoppage'),
                pagelength: 50,
                countonly: false
            }
            this.loadTemplateItems(initial);
            this.apidata_call(data);
        });
    }

    search(event, errors, values) {
        debugger;
        if (errors.length > 0) {

            console.log(errors);
        }
        else {
            this.setState({
                isLoading: true, cor: values.cor, cob: values.cob, batchid: values.batchid, activePage: 1, checkAll: false, checkEntire: false,
                selectedShops: [], location: values.location, competitor: values.competitor, scheduleday: values.scheduleday,
                shopname: values.shopname
            }, () => {

                localStorage.removeItem('shoppage');
                const data = {
                    page: 1, //(!localStorage.getItem('shoppage') || (localStorage.getItem('shoppage') === 'null')) ? 1 : localStorage.getItem('shoppage'),
                    pagelength: 50,
                    countonly: false
                }
                this.apidata_call(data);
            });
        }
    }

    reset() {
        this.setState({
            isLoading: true, cor: '', cob: '', batchid: '', activePage: 1, checkAll: false, checkEntire: false, selectedShops: [],
            location: '', competitor: '', scheduleday: '', scheduletime: '', shopname: ''
        }, () => {
            localStorage.removeItem('shoppage');
            const data = {
                page: 1, //(!localStorage.getItem('shoppage') || (localStorage.getItem('shoppage') === 'null')) ? 1 : localStorage.getItem('shoppage'),
                pagelength: 50,
                countonly: false
            }
            this.apidata_call(data);
        });
    }

    getSelectedSchedules() {
        return this.state.selectedShops.length > 0 ? this.state.selectedShops.join(',') : '';
    }

    render() {
        document.title = 'MyShop';
        const { isLoading, myshopData, open, schedId, templateItems, cor, cob, selectedShops, batchid, location, competitor
            , checkEntire, scheduleday, scheduletime, shopname
        } = this.state;
        const myshopsData = myshopData.myshops;
        //console.log(myshopsData);
        const myshopsPagination = myshopData.pagination;
        return (
            <div className="MyShop homepage">
                <Container className="body-content">
                    <Row>
                        <Col md={3}>
                            <Sidebar parentMethod={this.handler} parm={this.props} />
                        </Col>
                        <Col md={9}>
                            {/* {console.log(isLoading)} */}
                            {isLoading === true ? <div className="loading"></div> : ''}
                            <h4 className="graph-view">My Shop <AddShop parentMethod={this.handler1} parm={this.props} /></h4>
                            <div className="health-sources">
                                <div>
                                    <AvForm onSubmit={this.search.bind(this)}>
                                        <Row className="align-items-end float-blk my-shop-search-filter">
                                            <div className="col-md-3">
                                                <AvField type="text" name="batchid" label="Batch Id" value={batchid}>
                                                    <div className="clearfix"></div>
                                                </AvField>

                                            </div>
                                            <div className="col-md-3">
                                                <AvField type="select" name="scheduleday" label="Schedule Day" helpMessage="" value={scheduleday}>
                                                    <option value='' >--Select Day--</option>
                                                    {templateItems.scheduledays ? templateItems.scheduledays.map((s) =>
                                                        <option key={s.code} selected={scheduleday} value={s.code}>{s.label}</option>
                                                    ) : ''}
                                                    <div className="clearfix"></div>
                                                </AvField>

                                            </div>
                                            <div className="col-md-3">
                                                <AvField type="select" name="cor" label="COR" helpMessage="" value={cor}>
                                                    <option value='' >--Select Country--</option>
                                                    {templateItems.cor ? templateItems.cor.map((s) =>
                                                        <option key={s} selected={cor} value={s}>{s}</option>
                                                    ) : ''}
                                                    <div className="clearfix"></div>
                                                </AvField>

                                            </div>
                                            <div className="col-md-3">
                                                <AvField type="select" name="location" label="Location" helpMessage="" value={location}>
                                                    <option value='' >--Select Location--</option>
                                                    {templateItems.locations ? templateItems.locations.map((s) =>
                                                        <option key={s} selected={location} value={s}>{s}</option>
                                                    ) : ''}
                                                    <div className="clearfix"></div>
                                                </AvField>

                                            </div>
                                            <div className="col-md-3">
                                                <AvField type="text" name="shopname" label="Shop Name" value={shopname}>
                                                    <div className="clearfix"></div>
                                                </AvField>

                                            </div>
                                            <div className="col-md-3 schedule-time">
                                                <label>Schedule Time</label>
                                                <TimeInput
                                                    mode='24h'
                                                    onChange={(time) => this.handleTimeChange(time)}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <AvField type="select" name="cob" label="COB" helpMessage="" value={cob}>
                                                    <option value='' >--Select Country--</option>
                                                    {templateItems.cob ? templateItems.cob.map((s) =>
                                                        <option key={s} selected={cob} value={s}>{s}</option>
                                                    ) : ''}
                                                    <div className="clearfix"></div>
                                                </AvField>

                                            </div>

                                            <div className="col-md-3">
                                                <AvField type="select" name="competitor" label="Competitor" helpMessage="" value={competitor}>
                                                    <option value='' >--Select Competitor--</option>
                                                    {templateItems.competitor ? templateItems.competitor.map((s) =>
                                                        <option key={s} selected={competitor} value={s}>{s}</option>
                                                    ) : ''}
                                                    <div className="clearfix"></div>
                                                </AvField>

                                            </div>



                                            <div className="col-md-3 search-form-md">
                                                <Button id="btn" type="submit" className="need-spc-med-above search-btn" color="danger" size="lg" >Filter</Button>
                                                <Button id="btn" type="reset" className="need-spc-med-above reset-btn btn btn-secondary" onClick={this.reset} >Reset</Button>
                                            </div>
                                        </Row>
                                    </AvForm>
                                </div>
                                <div className="search-btm-result">
                                    <div className="primary-header">
                                        <Row>
                                            <Col md={8}>
                                                {myshopsData && myshopsData.length > 0
                                                    && (cor != '' || cob != '' || batchid != '' || location != '' || competitor != '' || scheduleday != '' || scheduletime != '' || shopname != '') ?

                                                    <div className="matching-schedules">
                                                        Total number of matching schedules are: {myshopsPagination.totalrecords}
                                                    </div>

                                                    : ''}

                                                <div>{selectedShops.length > 0 ? 
                                                <div className="selected-results"> You've selected {selectedShops.length + (selectedShops.length > 1 ? " items," : " item,")}
                                                </div>
                                                //  : <span className="no-items-select">No items selected.</span>}
                                                :''}

                                                    {
                                                        (myshopsData && myshopsData.length > 0) ?
                                                            <span>&nbsp;Select all listed schedules
                                                            <input type="checkbox" id="chkEntire" value="true" checked={this.state.checkEntire} onChange={event => this.handleCheckEntireShops(event)} />
                                                            </span>
                                                            : ''
                                                    }
                                                </div>
                                            </Col>
                                            <Col md={4} className="right-matching-item">
                                            {selectedShops.length > 0 ?
                                                    <div className="delete-store-btns">
                                                        {
                                                            // (myshop.statusname === 'Active') || myshop.statusname === 'Inactive' ?
                                                            // <Button onClick={event => this.handleClickOpen(myshop.scheduleid)} className="delete-icon"><img src={delete1} alt="delete" /></Button>
                                                            // : <Button onClick={event => this.updateShopClick(myshop.scheduleid)} className="delete-icon"><img src={restore} alt="restore" /></Button>
                                                            <Button onClick={event => this.deleteMultipleShops()} className="delete-icon"><img src={delete1} alt="delete" /></Button>
                                                        }
                                                    </div>
                                                    : ''
                                                }

                                                {
                                                    selectedShops.length > 0 ?
                                                        <div className="toggle-btns">
                                                            {
                                                                // (myshop.statusname === 'Active') || myshop.statusname === 'Inactive' ?
                                                                // <Switch onChange={event => this.handleChange(myshop.scheduleid, myshop.statusname)} checked={myshop.statusname === 'Active'} />
                                                                // : ''
                                                                <Switch id="swt" onChange={event => this.handleMultipleShops(event)} checked="true" />
                                                            }

                                                        </div>
                                                        : ''
                                                }
                                                
                                                {selectedShops.length > 0 ?
                                                    <div className="update-btns">
                                                        {
                                                            <UpdateTemplate parentMethod={this.getSelectedSchedules} parm={this.state.selectedShops} />
                                                        }
                                                    </div>
                                                    : ''
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="table-responsive my-shop-table">
                                    <Table cellSpacing="0" >
                                        <TableHead className="my-shop-tbl-sec-head">
                                            <TableRow>
                                                <TableCell className="selectCheck">
                                                    Select <br />
                                                    <input type="checkbox" id="chkAll" value="true" checked={this.state.checkAll || this.state.checkEntire} onChange={event => this.handleCheckAll(event)} />
                                                </TableCell>
                                                <TableCell>Shop Name</TableCell>
                                                <TableCell className="batch-id">Batch Id</TableCell>
                                                <TableCell className="sch-day">Schedule Day</TableCell>
                                                <TableCell>Created Date</TableCell>
                                                <TableCell>Last Run Date</TableCell>
                                                <TableCell>Next Run Date</TableCell>
                                                <TableCell>COR</TableCell>
                                                <TableCell>COB</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {myshopsData && myshopsData.length ? myshopsData.map(myshop => (
                                                <TableRow key={myshop.scheduleid + myshop.cor + myshop.cob}>
                                                    <TableCell className="selectCheck">
                                                        <input type="checkbox" id={myshop.scheduleid} checked={(selectedShops.indexOf(myshop.scheduleid) >= 0)} value={myshop.scheduleid} onChange={event => this.handleShopCheck(event, myshop.scheduleid)} />
                                                    </TableCell>
                                                    <TableCell>
                                                        {myshop.shopname}
                                                    </TableCell>
                                                    <TableCell className="batch-id">
                                                        {myshop.batchid}
                                                    </TableCell>
                                                    <TableCell className="sch-day">
                                                        {myshop.dayofweek}
                                                    </TableCell>
                                                    <TableCell>{myshop.createddate ? moment(myshop.createddate).format("L, LT") : <div className="hypen">-</div>}</TableCell>
                                                    <TableCell>{myshop.lastrundate ? moment(myshop.lastrundate).format("L, LT") : <div className="hypen">-</div>}</TableCell>
                                                    <TableCell>{myshop.nextrundate ? moment(myshop.nextrundate).format("L, LT") : <div className="hypen">-</div>}</TableCell>
                                                    <TableCell>{myshop.cor}</TableCell>
                                                    <TableCell>{myshop.cob}</TableCell>
                                                    <TableCell>
                                                        {(myshop.statusname === 'Active') || myshop.statusname === 'Inactive' ?
                                                            <Switch onChange={event => this.handleChange(myshop.scheduleid, myshop.statusname)} checked={myshop.statusname === 'Active'} />
                                                            : ''
                                                        }

                                                    </TableCell>
                                                    <TableCell className="delete-restore">
                                                        {(myshop.statusname === 'Active') || myshop.statusname === 'Inactive' ?
                                                            <Button onClick={event => this.handleClickOpen(myshop.scheduleid)} className="delete-icon"><img src={delete1} alt="delete" /></Button>
                                                            : <Button onClick={event => this.updateShopClick(myshop.scheduleid)} className="delete-icon"><img src={restore} alt="restore" /></Button>
                                                        }

                                                    </TableCell>

                                                </TableRow>
                                            )) : <TableRow ><TableCell colSpan="11" align="center" className="no-records">No records Found</TableCell></TableRow>}
                                        </TableBody>
                                    </Table>
                                </div>
                                {myshopsPagination && myshopsPagination.totalrecords > 0 ?
                                    <div className="float-left">
                                        <div className="sho"> Showing from {localStorage.getItem('shoppage') > 1 ? ' ' + (((localStorage.getItem('shoppage') - 1) * myshopsPagination.pagelength) + 1) : '1'} to {(myshopsData.length === 50) && (!localStorage.getItem('shoppage')) ? ' ' + 50 : (myshopsData.length === 50) && (localStorage.getItem('shoppage') > 0) ? ' ' + (myshopsPagination.pagelength * localStorage.getItem('shoppage')) : ''}{(myshopsData.length < 50) && (localStorage.getItem('shoppage') > 1) ? ' ' + myshopsPagination.totalrecords + '' : ''}{(myshopsData.length < 50) && (!localStorage.getItem('shoppage')) ? myshopsData.length :  ''} of {myshopsPagination.totalrecords} entries.</div>
                                    </div> : ''}
                                {myshopsPagination && myshopsPagination.totalrecords > 50 ?
                                    <div className="float-right">
                                        <div>
                                            <Pagination
                                                hideDisabled
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={myshopsPagination.pagelength}
                                                totalItemsCount={myshopsPagination.totalrecords}
                                                pageRangeDisplayed={10}
                                                onChange={this.handlePageChange.bind(this)}
                                                nextPageText={<ArrowRight />}
                                                prevPageText={<ArrowLeft />}
                                                firstPageText={<FastRewind />}
                                                lastPageText={<FastForward />}
                                            />
                                        </div>
                                    </div> : ''
                                }
                                <div className="clearfix"></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Dialog
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="confirm"
                >
                    <DialogTitle id="alert-dialog-title">Remove Shop</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this Shop?
          </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.deleteShopClick} color="primary" autoFocus>
                            Yes
          </Button>
                        <Button onClick={this.handleClose} color="danger" >
                            No
          </Button>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}


export default MyShop;