import React, { Component } from 'react';
import './Schedules.css';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button } from 'reactstrap';
import { AddShop } from '../AddShop';
import delete1 from './../../Assets/Img/delete.png';
import download1 from './../../Assets/Img/download.png';
import restore from './../../Assets/Img/restore.png';
import axios from 'axios';
import moment from 'moment';
import { CONFIG } from '../../utils/config';
import Pagination from "react-js-pagination";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import FastForward from '@material-ui/icons/FastForward';
import FastRewind from '@material-ui/icons/FastRewind';
import { Container, Row, Col } from 'reactstrap';
import { Sidebar } from '../../Core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
class Schedules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      expanded: false,
      errorexpanded: false,
      myschedulesData: [],
      myschedules: [],
      scheduledShops: [],
      scheduleid: null,
      errorData: [],
      rundate: null,
      activePage: 1//(!localStorage.getItem('schedpaginationpage') || (localStorage.getItem('schedpaginationpage') === 'null')) ? 1 : localStorage.getItem('schedpaginationpage'),


    };
    this.handleChange = this.handleChange.bind(this);
    this.errorhandleChange = this.errorhandleChange.bind(this);
    this.scheduledShops = this.scheduledShops.bind(this);
    this.updateShopClick = this.updateShopClick.bind(this);
    this.deleteShopClick = this.deleteShopClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.errorClick = this.errorClick.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
  }
  errorClick(shopid, scheduleid, lastrundate) {
    debugger;
    this.setState({
      isLoading: true
    });
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    var data = {
      shopid: shopid,
      scheduleid: scheduleid,
      rundate: lastrundate
    }
    axios.post(CONFIG.API_URL + 'shop/schedule/errorcodes',data)
      .then(res => {

        const errorData = res.data;
        this.setState({ isLoading: false, errorData });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        alert(error.response.data);
      });
  }
  handleClickOpen(scheduleid, rundate, status) {
    this.setState({
      open: true,
      scheduleid: scheduleid,
      rundate: rundate,
      status: status
    });
  }

  handleClose() {
    this.setState({
      open: false
    });
  }

  updateShopClick(scheduleid, rundate, status) {

    this.setState({
      isLoading: true,

    });
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.put(CONFIG.API_URL + 'shop/schedule/restore/' + scheduleid)
      .then(res => {


        this.componentDidMount();
        this.scheduledShops(rundate, status);
      })

      .catch((err) => {

        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          localStorage.removeItem('clientid');
          localStorage.removeItem('clientname');
          localStorage.removeItem('clientlogo');
          localStorage.removeItem('role');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }

  deleteShopClick() {
    this.setState({
      isLoading: true
    });

    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }

    axios.request({
      url: CONFIG.API_URL + 'shop/schedule/' + this.state.scheduleid,
      method: 'delete',
    })
      .then(res => {
        this.componentDidMount();
        this.scheduledShops(this.state.rundate, this.state.status);
        this.setState({ scheduleid: '', rundate: '', status: '', open: false })
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        });
        alert(err);
      });
  }

  downloadFile(shopstatusid, options, completedfile, errorfile) {
    this.setState({
      isLoading: true
    });

    var data = {
      shopstatusid: shopstatusid,
      options: options
    }
    var url = CONFIG.API_URL + 'shop/download';
    axios.request({ url: url, method: 'post', data, responseType: 'blob' })

      .then((blob) => {
        debugger;
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement('a');
        link.href = url;

        var filename = options == 'completed' ? completedfile : errorfile;
        filename = filename.substring(filename.lastIndexOf('\\') + 1);

        link.setAttribute('download', filename);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
        this.setState({
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        alert(error.response.data);
      });
  }

  scheduledShops(rundate, status) {
    this.setState({
      isLoading: true
    });
    const data = {
      "rundate": rundate,
      "status": status
    }
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'shop/scheduledshops', data)
      .then(res => {

        const scheduledShops = res.data;
        this.setState({ isLoading: false, scheduledShops });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        alert(error.response.data);
      });
  }

  handleChange = panel => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };
  errorhandleChange = panel => (event, isExpanded) => {
    this.setState({ errorexpanded: isExpanded ? panel : false });
  };
  handlePageChange(pageNumber) {
    window.scrollTo(0, 0);
    localStorage.setItem('schedpaginationpage', pageNumber ? pageNumber : null);
    this.setState({ activePage: pageNumber, isLoading: true });
    const data = {
      page: pageNumber,
      pagelength: 10
    }
    data.Page = pageNumber;

    this.apidata_call(data);
  }
  apidata_call(data) {
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'shop/schedules', data)
      .then(res => {

        const myschedules = res.data;
        const myschedulesData = res.data.myschedules;
        console.log(myschedulesData);
        this.setState({ isLoading: false, myschedules, myschedulesData });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        alert(error.response.data);
      });
  }
  componentDidMount() {
    this.setState({
      isLoading: true
    });
    const data = {
      page: this.state.activePage,//(!localStorage.getItem('schedpaginationpage') || (localStorage.getItem('schedpaginationpage') === 'null')) ? 1 : localStorage.getItem('schedpaginationpage'),
      pagelength: 10
    }
    this.apidata_call(data);
  }
  render() {
    //const classes = useStyles();
    const { isLoading, expanded, errorexpanded, myschedulesData, myschedules, scheduledShops, open, errorData } = this.state;
    const myschedulesPagination = myschedules.pagination;
    document.title = 'My Schedules';
    console.log(myschedulesData);
    console.log(scheduledShops);
    return (
      <div className="Schedules homepage" >
        <Container className="body-content">
          <Row>
            <Col md={3}>
              <Sidebar parentMethod={this.handler} parm={this.props} />
            </Col>
            <Col md={9}>


              {isLoading ?
                <div className="loading"></div>
                : ''}

              <h4 className="graph-view">My Schedules <AddShop parentMethod={this.handler1} parm={this.props} /></h4>
              <Table className="schedule-top sche">
                <TableHead>
                  <TableRow>
                    <TableCell>Run Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Run Count</TableCell>
                  </TableRow>
                </TableHead>
              </Table>

              {myschedulesData.length > 0 ? myschedulesData.map(myschedule => (
                <div className="panel-bot odd-even-grid">
                  <ExpansionPanel expanded={expanded === 'panel1' + myschedule.id} onChange={this.handleChange('panel1' + myschedule.id)} className="schedule-grid shec">
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      onClick={() => { this.scheduledShops(myschedule.rundate, myschedule.status) }}>
                      <Typography >{moment(myschedule.rundate).format("MM/DD/YYYY hh:00 A")}</Typography>
                      <Typography className={myschedule.status}>{myschedule.status}</Typography>
                      <Typography className='count'>{myschedule.callcount}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="expandable-view">

                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Shop Name</TableCell>
                            <TableCell align="center">Last Run Date</TableCell>
                            <TableCell align="center">Next Run Date</TableCell>
                            <TableCell align={myschedule.status === 'Failed' ? 'left' : 'center'}>Run Count</TableCell>
                            <TableCell align="center"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(scheduledShops.length > 0) && (myschedule.status !== 'Failed') ? scheduledShops.map(scheduled => (
                            <TableRow key={scheduled.id}>
                              <TableCell component="th" scope="row">{scheduled.shopname}</TableCell>
                              <TableCell align="center">{scheduled.lastrundate ? moment(scheduled.lastrundate).format("MM/DD/YYYY hh:00 A") : <div className="hypen">-</div>}</TableCell>
                              <TableCell align="center">{scheduled.nextrundate ? moment(scheduled.nextrundate).format("MM/DD/YYYY hh:00 A") : <div className="hypen">-</div>}</TableCell>
                              <TableCell align="center">{scheduled.callcount}</TableCell>
                              <TableCell align="center">
                                {scheduled.errorfile ?
                                  <Button className="override-btn" onClick={event => this.downloadFile(scheduled.shopstatusid, scheduled.downloadfile, scheduled.completedfile, scheduled.errorfile)}><img src={download1} alt="download" /></Button>
                                  : ''}
                                {(scheduled.statusname === 'Active') || scheduled.statusname === 'Inactive' ?
                                  <Button onClick={event => this.handleClickOpen(scheduled.scheduleid, myschedule.rundate, myschedule.status)} className="delete-icon override-btn"><img src={delete1} alt="delete" /></Button>
                                  : <Button onClick={event => this.updateShopClick(scheduled.scheduleid, myschedule.rundate, myschedule.status)} className="delete-icon override-btn"><img src={restore} alt="delete" /></Button>
                                }

                              </TableCell>
                            </TableRow>

                          ))
                            : (scheduledShops.length > 0) && (myschedule.status === 'Failed') ?
                              <TableRow><TableCell colspan="5" className="no-padd">
                                {scheduledShops.map(scheduled => (
                                  <ExpansionPanel expanded={errorexpanded === 'error1' + scheduled.id} onChange={this.errorhandleChange('error1' + scheduled.id)}>
                                    <ExpansionPanelSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1bh-content"
                                      id="panel1bh-header"

                                      onClick={myschedule.status === 'Failed' ? () => { this.errorClick(scheduled.shopid, scheduled.scheduleid, moment(myschedule.rundate).format("L")) } : ''}
                                    >
                                      <Typography className="sche-shopname">{scheduled.shopname}</Typography>
                                      <Typography className="sche-lrundate">{scheduled.lastrundate ? moment(scheduled.lastrundate).format("MM/DD/YYYY hh:00 A") : <div className="hypen">-</div>}</Typography>
                                      <Typography className="sche-nrundate">{scheduled.nextrundate ? moment(scheduled.nextrundate).format("MM/DD/YYYY hh:00 A") : <div className="hypen">-</div>}</Typography>
                                      <Typography className="sche-count">{scheduled.callcount}</Typography>
                                      <Typography className="sche-delet">
                                        {scheduled.downloadfile ?
                                          <Button className="override-btn" onClick={event => this.downloadFile(scheduled.shopstatusid, scheduled.downloadfile, scheduled.completedfile, scheduled.errorfile)}><img src={download1} alt="download" /></Button>
                                          : ''}
                                        {(scheduled.statusname === 'Active') || scheduled.statusname === 'Inactive' ?
                                          <Button onClick={event => this.handleClickOpen(scheduled.scheduleid, myschedule.rundate, myschedule.status)} className="delete-icon override-btn"><img src={delete1} alt="delete" /></Button>
                                          : <Button onClick={event => this.updateShopClick(scheduled.scheduleid, myschedule.rundate, myschedule.status)} className="delete-icon override-btn"><img src={restore} alt="delete" /></Button>
                                        }
                                      </Typography>

                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="error-panel">
                                      <Table>
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Error Codes</TableCell>
                                            <TableCell>Calls</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {errorData.length > 0 ? errorData.map(error => (
                                            <TableRow key={error.errorcode}>
                                              <TableCell component="th" scope="row">
                                                {error.errorcode} - {error.errormessage}
                                              </TableCell>
                                              <TableCell>{error.callscount}</TableCell>
                                            </TableRow>
                                          ))
                                            : <TableRow><TableCell colspan="2" align="center" className="no-records">No records Found</TableCell></TableRow>
                                          }
                                        </TableBody>
                                      </Table>
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel>
                                ))}
                              </TableCell></TableRow>

                              : <TableRow><TableCell colspan="4" align="center" className="no-records">There is no Schedule found.</TableCell></TableRow>
                          }
                        </TableBody>
                      </Table>

                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              )) : <p className="no-data-style">There is no Schedule found.</p>}
              {myschedulesPagination && myschedulesPagination.totalrecords > 0 ?
                <div className="float-left">
                  <div className="sho"> Showing from {localStorage.getItem('schedpaginationpage') > 1 ? ' ' + localStorage.getItem('schedpaginationpage') - 1 : ''}1 to {(myschedulesData.length === 10) && (!localStorage.getItem('schedpaginationpage')) ? ' ' + 10 : (myschedulesData.length === 10) && (localStorage.getItem('schedpaginationpage') > 0) ? ' ' + localStorage.getItem('schedpaginationpage') + '0' : ''}{(myschedulesData.length < 10) && (localStorage.getItem('schedpaginationpage') > 1) ? ' ' + (localStorage.getItem('schedpaginationpage') - 1) + '' + myschedulesData.length + '' : ''}{(myschedulesData.length < 10) && (!localStorage.getItem('schedpaginationpage')) ? myschedulesData.length : ''} of {myschedulesPagination.totalrecords} entries.</div>
                </div> : ''}
              {myschedulesPagination && myschedulesPagination.totalrecords > 10 ?
                <div className="float-right">
                  <div>
                    <Pagination
                      hideDisabled
                      activePage={this.state.activePage}
                      itemsCountPerPage={myschedulesPagination.pagelength}
                      totalItemsCount={myschedulesPagination.totalrecords}
                      pageRangeDisplayed={10}
                      onChange={this.handlePageChange.bind(this)}
                      nextPageText={<ArrowRight />}
                      prevPageText={<ArrowLeft />}
                      firstPageText={<FastRewind />}
                      lastPageText={<FastForward />}
                    />
                  </div>
                </div> : ''
              }
              <div className="clearfix"></div>
            </Col>
          </Row>
        </Container>
        <Dialog
          open={open}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="confirm"
        >
          <DialogTitle id="alert-dialog-title">Remove Shop</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.deleteShopClick} color="primary" autoFocus>
              Yes
          </Button>
            <Button onClick={this.handleClose} color="danger" >
              No
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default Schedules;