import React, { Component } from 'react';
import './UpdateTemplate.css';
import { Button, Form, FormGroup, Input, Col, Row } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import { CONFIG } from '../../utils/config';
import NewWindow from 'react-new-window';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Select from 'react-select';
import TimeInput from 'material-ui-time-picker';

class UpdateTemplate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scheduleids: '',
            update: false,
            isLoading: false,
            errors: {},
            uscheduleday: '',
            uscheduletime: '',
            ucor: '',
            ulor: '',
            uratetype: '',
            ucob: '',
            uadv: '',
            ucompetitor: '',
            ulocation: '',
            upickuptime: '',
            udropofftime: '',
            usuppliercode: '',
            ucors: [],
            ucobs: [],
            ucompetitors: [],
            ulocations: [],
            usuppliercodes: [],
            uscheduledays: [],
            scheduleDayOptions: [],
            corOptions: [],
            cobOptions: [],
            competitorOptions: [],
            locationOptions: [],
            suppliercodeOptions: []
        };
        this.updateTemplatePopup = this.updateTemplatePopup.bind(this);
        this.updateTemplateClose = this.updateTemplateClose.bind(this);
        this.setScheduleDay = this.setScheduleDay.bind(this);
        this.setCOB = this.setCOB.bind(this);
        this.setCOR = this.setCOR.bind(this);
        this.setCompetitor = this.setCompetitor.bind(this);
        this.setLocation = this.setLocation.bind(this);
        this.setSupplier = this.setSupplier.bind(this);
        this.getSelectedSchedules = this.getSelectedSchedules.bind(this);
        this.getOptionsValue = this.getOptionsValue.bind(this);
        this.handleScheduleTimeChange = this.handleScheduleTimeChange.bind(this);
        this.onlyNumeric = this.onlyNumeric.bind(this);
    }

    componentDidMount() {
        debugger;
        const token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        axios.get(CONFIG.API_URL + 'lookup/clientvalues/' + localStorage.getItem('clientid'))
            .then(res => {
                debugger;
                let clientValues = res.data;
                let ucors = clientValues.cors;
                let ucobs = clientValues.cobs;
                let ulors = clientValues.lors;
                let ucompetitors = clientValues.competitors;
                let ulocations = clientValues.locations;
                let usuppliercodes = clientValues.suppliercodes;
                let uscheduledays = clientValues.scheduledays;

                this.setState({
                    isLoading: false,
                    ucors, ucobs, ulors, ucompetitors, ulocations, usuppliercodes, uscheduledays
                });

            })
            .catch((err) => {
                if (err.response.status === 401) {
                    console.log(err.response.data);
                }
                else {
                    alert(err.response.data);
                }

            });
        debugger;
        this.setState({ scheduleids: this.props.parm.join(',') });
    }

    handleScheduleTimeChange(time) {
        this.setState({ uscheduletime: time.toTimeString().split(' ')[0].substr(0, 5) });
    }

    getSelectedSchedules = () => {
        debugger;
        this.setState({ scheduleids: this.props.parentMethod() });
    }

    getOptionsValue() {
        var { scheduleDayOptions, cobOptions, corOptions, competitorOptions, locationOptions, suppliercodeOptions,
            uscheduleday, ucob, ucor, ucompetitor, ulocation, usuppliercode } = this.state;
        var arr_scheduleday = [], arr_cob = [], arr_cor = [], arr_competitor = [], arr_location = [], arr_suppliercode = [];

        if (scheduleDayOptions.length > 0) {
            scheduleDayOptions.map((s) => {
                arr_scheduleday.push(s.value);
            });

            uscheduleday = arr_scheduleday.join('');
        }

        if (cobOptions.length > 0) {
            cobOptions.map((s) => {
                arr_cob.push(s.value);
            });

            ucob = arr_cob.join(',');
        }

        if (corOptions.length > 0) {
            corOptions.map((s) => {
                arr_cor.push(s.value);
            });

            ucor = arr_cor.join(',');
        }

        if (competitorOptions.length > 0) {
            competitorOptions.map((s) => {
                arr_competitor.push(s.value);
            });

            ucompetitor = arr_competitor.join(',');
        }

        if (locationOptions.length > 0) {
            debugger;
            locationOptions.map((s) => {
                arr_location.push(s.value);
            });

            ulocation = arr_location.join(',');
        }

        if (suppliercodeOptions.length > 0) {
            suppliercodeOptions.map((s) => {
                arr_suppliercode.push(s.value);
            });

            usuppliercode = arr_suppliercode.join(' ');
        }

        this.setState({ uscheduleday, ucob, ucor, ucompetitor, ulocation, usuppliercode });

    }

    updateTemplate(e, errors, values) {
        debugger;
        if(errors.length<=0)
        {
        this.getSelectedSchedules();
        this.getOptionsValue();
        const data = {
            "scheduleid": this.state.scheduleids,
            "scheduledate": this.state.uscheduleday,
            "scheduletime": this.state.uscheduletime,
            "cor": this.state.ucor,
            "lor": values.ulor,
            "ratetype": values.uratetype,
            "cob": this.state.ucob,
            "adv": values.uadv,
            "competitor": this.state.ucompetitor,
            "location": this.state.ulocation,
            "suppliercode": this.state.usuppliercode,
            "pickuptime": values.upickuptime,
            "dropofftime": values.udropofftime
        };
        const token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        axios.post(CONFIG.API_URL + 'shop/update', data)
            .then(res => {
                //this.setState({ update: false, schedule: '', shopname: '', template: '', errors: {} })
                this.setState({ update: false, errors: {} })
                let path = `myshop`;
                let  schedulesLength = this.state.scheduleids.split(',').length;
                alert('You have batch updated ' + schedulesLength.toString() + (schedulesLength>1?' schedules' : ' schedule') + ' and the new configuration would take 6 hours.');
                this.updateTemplateClose(true);
                //this.props.parm.history.push(path);
                // var isSearchPage = this.props.parm.location.pathname, expr = /myshop/;
                // if (isSearchPage.match(expr)) {
                //     this.props.parentMethod();

                // }
            })
            .catch((err) => {
                debugger;
                this.setState({
                    isLoading: false
                });

                if (err.response != undefined && err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    localStorage.removeItem('clientid');
                    localStorage.removeItem('clientname');
                    localStorage.removeItem('clientlogo');
                    localStorage.removeItem('role');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
        }
    }

    updateTemplatePopup() {
        this.setState({
            update: true
        })
    }

    updateTemplateClose(reloadParent) {
        // this.setState({
        //     update: false, schedule: '', shopname: '', template: '', errors: {}, files: ''
        // })
        this.setState({
            update: false, uscheduleday: '', uscheduletime: '', uadv: '', ucob: '', ucor: '', ucompetitor: '', usuppliercode: '', ulocation: '', udropofftime: '', upickuptime: '', ulor: '', uratetype: '', errors: {}, files: ''
        }, () => {
            if (reloadParent) {
                window.location.reload();
            }
        });
    }

    setScheduleDay(selectedOption) {
        this.setState({ scheduleDayOptions: selectedOption });
    }

    setCOB(selectedOption) {
        this.setState({ cobOptions: selectedOption });
    }

    setCOR(selectedOption) {
        this.setState({ corOptions: selectedOption });
    }

    setCompetitor(selectedOption) {
        this.setState({ competitorOptions: selectedOption });
    }

    setLocation(selectedOption) {
        this.setState({ locationOptions: selectedOption });
    }

    setSupplier(selectedOption) {
        this.setState({ suppliercodeOptions: selectedOption });
    }

    onRemove(optionsList, selectedItem) {
        debugger;
        this.setState({ uscheduleday: selectedItem });
    }

    onlyNumeric(e){
        debugger;
        const re = /^[0-9\b]+$/;
    
        // if value is not blank, then test the regex
    
        if (e.target.value === '' || re.test(e.target.value)) {
           this.setState({ulor: e.target.value})
        }
    }
    render() {
        const { update, uscheduleday, uscheduletime, ucor, ulor, uratetype, ucob, uadv, ucompetitor, ulocation, upickuptime, udropofftime,
            usuppliercode, ucors, ucobs, ucompetitors, ulocations, usuppliercodes, uscheduledays, scheduleDayOptions, cobOptions,
            corOptions, competitorOptions, locationOptions, suppliercodeOptions
        } = this.state;

        return (
            <div>
                <Button onClick={this.updateTemplatePopup} className="add-new-shop">Update</Button>
                <Dialog
                    open={update}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="add-new-shop shop-edit">
                    <AvForm onSubmit={this.updateTemplate.bind(this)}>
                        <DialogTitle id="alert-dialog-title" className="title-popup">Shop Edit</DialogTitle>

                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <p>Please choose the parameters below to override the shop configurations</p>
                                <div className="previewComponent">
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup className="shop-name">
                                                <label>Schedule Day:</label>
                                                <Select options={uscheduledays} isMulti="true" name="uscheduleday" onChange={this.setScheduleDay} value={scheduleDayOptions} />
                                                <div className="clear"></div>
                                                <div className="errorMsg home-errormsg">{this.state.errors.uscheduleday}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="shop-name schedule-times">
                                                <label>Schedule Time:</label>
                                                <TimeInput
                                                    mode='24h'
                                                    onChange={(time) => this.handleScheduleTimeChange(time)}
                                                />
                                                <div className="clear"></div>
                                                <div className="errorMsg home-errormsg">{this.state.errors.uscheduletime}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="shop-name">
                                                <label>COR:</label>
                                                <Select options={ucors} isMulti="true" name="ucor" onChange={this.setCOR} value={corOptions} />
                                                <div className="clear"></div>
                                                <div className="errorMsg home-errormsg">{this.state.errors.ucor}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="shop-name">
                                                <AvField type="text"  name="ulor" validate={{pattern:{value:"^[0-9|,|-]*$",errorMessage:"Only numeric values, (,) and (-) are allowed"}}} label="LOR:" id="ulor" maxLength="100" bsSize="lg" value={this.state.ulor} placeholder="1,2 or 1-2" />
                                                <div className="clear"></div>
                                                <div className="errorMsg home-errormsg">{this.state.errors.ulor}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="shop-name">
                                                <AvField type="text" label="Rate Type:" name="uratetype" id="uratetype" maxLength="100" bsSize="lg" value={uratetype} placeholder="" />
                                                <div className="clear"></div>
                                                <div className="errorMsg home-errormsg">{this.state.errors.uratetype}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="shop-name">
                                                <label>COB:</label>
                                                <Select options={ucobs} isMulti="true" name="ucob" onChange={this.setCOB} value={cobOptions} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="shop-name">
                                                <AvField type="text" label="ADV:" validate={{pattern:{value:"^[0-9|,|-]*$",errorMessage:"Only numeric values, (,) and (-) are allowed"}}} name="uadv" id="uadv" maxLength="100" bsSize="lg" value={uadv} placeholder="1,2 or 1-2" />
                                                <div className="clear"></div>
                                                <div className="errorMsg home-errormsg">{this.state.errors.uadv}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="shop-name">
                                                <label>Competitor:</label>
                                                <Select options={ucompetitors} isMulti="true" name="ucompetitor" onChange={this.setCompetitor} value={competitorOptions} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="shop-name">
                                                <label>Location:</label>
                                                <Select options={ulocations} isMulti="true" name="ulocation" onChange={this.setLocation} value={locationOptions} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="shop-name">
                                                <AvField type="text" label="Pick-up Time:" validate={{pattern:{value:"^([0-1][0-9]|[2][0-3]):([0-5][0-9])$",errorMessage:"Enter only time value in 00:00 format."}}} name="upickuptime" id="upickuptime" maxLength="5" bsSize="lg" value={upickuptime} placeholder="00:00" />
                                                <div className="clear"></div>
                                                <div className="errorMsg home-errormsg">{this.state.errors.upickuptime}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="shop-name">
                                                <AvField type="text" name="udropofftime" validate={{pattern:{value:"^([0-1][0-9]|[2][0-3]):([0-5][0-9])$",errorMessage:"Enter only time value in 00:00 format."}}} label="Drop Off Time:" id="udropofftime" maxLength="5" bsSize="lg" value={udropofftime} placeholder="00:00" />
                                                <div className="clear"></div>
                                                <div className="errorMsg home-errormsg">{this.state.errors.udropofftime}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="shop-name">
                                                <label>Supplier Code:</label>
                                                <Select options={usuppliercodes} isMulti="true" name="usuppliercode" onChange={this.setSupplier} value={suppliercodeOptions} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="submitButton"
                                type="submit">Update</Button>
                            <Button onClick={()=>this.updateTemplateClose(false)} color="primary" className="reset-btn">Cancel</Button>

                        </DialogActions>
                    </AvForm>
                </Dialog>

                {
                    (this.state.showDirectRules) ?
                        <NewWindow title="Direct Upload Template Rules" center="Screen" onUnload={this.showDirectRulesClose}>
                            <div className="rules">
                                <h3>Direct Upload Template Rules</h3>
                                <ul>
                                    <li>The first row must contain the column names</li>
                                    <li>The column names must match those specified in the list below:
                                    <ol>
                                            <li>BatchID <span className="errorMsg">*</span></li>
                                            <li>ScheduleDate <span className="errorMsg">*</span></li>
                                            <li>cor</li>
                                            <li>lor <span className="errorMsg">*</span></li>
                                            <li>ratetype</li>
                                            <li>cob <span className="errorMsg">*</span></li>
                                            <li>adv <span className="errorMsg">*</span></li>
                                            <li>competitor <span className="errorMsg">*</span></li>
                                            <li>locgroup</li>
                                            <li>locations <span className="errorMsg">*</span></li>
                                            <li>pickuptime <span className="errorMsg">*</span></li>
                                            <li>dropofftime <span className="errorMsg">*</span></li>
                                        </ol>
                                    </li>
                                </ul>
                                <p class="info">All the mandatory fields are marked with (<span className="errorMsg">*</span>).</p>
                            </div>
                        </NewWindow>
                        : ''
                }
                {
                    (this.state.showIntermediariesRules) ?
                        <NewWindow title="Intermediaries Upload Template Rules" center="Screen" onUnload={this.showIntermediariesRulesClose}>
                            <div className="rules">
                                <h3>Intermediaries Upload Template Rules</h3>
                                <ul>
                                    <li>The first row must contain the column names</li>
                                    <li>The column names must match those specified in the list below:
                                    <ol>
                                            <li>BatchID <span className="errorMsg">*</span></li>
                                            <li>ScheduleDate <span className="errorMsg">*</span></li>
                                            <li>cor</li>
                                            <li>lor <span className="errorMsg">*</span></li>
                                            <li>ratetype</li>
                                            <li>cob <span className="errorMsg">*</span></li>
                                            <li>adv <span className="errorMsg">*</span></li>
                                            <li>competitor <span className="errorMsg">*</span></li>
                                            <li>locgroup</li>
                                            <li>locations <span className="errorMsg">*</span></li>
                                            <li>pickuptime <span className="errorMsg">*</span></li>
                                            <li>dropofftime <span className="errorMsg">*</span></li>
                                            <li>suppliercodes</li>
                                            <li>minimumpricefile</li>
                                            <li>radius</li>
                                        </ol>
                                    </li>
                                </ul>
                                <p class="info">All the mandatory fields are marked with (<span className="errorMsg">*</span>).</p>
                            </div>
                        </NewWindow>
                        : ''
                }
            </div >

        );
    }
}


export default UpdateTemplate;