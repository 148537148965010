import React, { Component } from 'react';
import './NotFound.css';

class NotFound extends Component {
    constructor(props) {
        super(props);

        this.state = {
        
        };
       
    }
    componentDidMount() {
        

    }
   
    render() {
        document.title = 'NotFound';
       
            return (
                <div className="NotFound">
                <p>NotFound View</p>
                    
                </div>
            );
        }
    }


export default NotFound;