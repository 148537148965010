import React, { Component } from 'react';
import './SearchResults.css';
import { Container, Row, Col } from 'reactstrap';
import { Sidebar } from '../../Core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import { CONFIG } from '../../utils/config';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { AddShop } from '../AddShop';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import FastForward from '@material-ui/icons/FastForward';
import FastRewind from '@material-ui/icons/FastRewind';
import download1 from './../../Assets/Img/download.png';
import { Button } from 'reactstrap';
import delete1 from './../../Assets/Img/delete.png';
import restore from './../../Assets/Img/restore.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class SearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      errorexpanded: false,
      searchdata: [],
      searchshopData: [],
      scheduledShops: [],
      activePage: 1,//(!localStorage.getItem('searchpaginationpage') || (localStorage.getItem('searchpaginationpage') === 'null')) ? 1 : localStorage.getItem('searchpaginationpage'),
      errorData: [],
      isLoading: false,
      open: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.errorhandleChange = this.errorhandleChange.bind(this);
    this.scheduledShops = this.scheduledShops.bind(this);
    this.updateShopClick = this.updateShopClick.bind(this);
    this.deleteShopClick = this.deleteShopClick.bind(this);
    this.errorClick = this.errorClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handler = this.handler.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
  }

  handler() {
    this.componentDidMount();
  }

  handleChange = panel => (event, isExpanded) => {
    debugger;
    this.setState({ expanded: false, errorexpanded: false, scheduledShops:[], errorData: [] }, () => {
      this.setState({ expanded: isExpanded ? panel : false });
    });
  };

  errorhandleChange = panel => (event, isExpanded) => {
    debugger;
    this.setState({ errorexpanded: false, errorData: [] }, () => {
      this.setState({ errorexpanded: isExpanded ? panel : false });
    });
  };

  handleClickOpen(scheduleid, rundate, status) {
    this.setState({
      open: true,
      scheduleid: scheduleid,
      rundate: rundate,
      status: status
    });
  }

  handleClose() {
    this.setState({
      open: false
    });
  }

  scheduledShops(rundate, status) {
    debugger;
    this.setState({
      isLoading: true
    });
    var batchid = (localStorage.getItem('name') && localStorage.getItem('name') != 'null') ? localStorage.getItem('name') : '';
    const data = {
      "rundate": rundate,
      "status": status,
      "batchid": batchid
    }
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'shop/scheduledshops', data)
      .then(res => {

        const scheduledShops = res.data;
        console.log('test');
        console.log(scheduledShops);
        this.setState({ isLoading: false, scheduledShops });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        alert(error.response.data);
      });
  }

  downloadFile(shopstatusid, options, completedfile, errorfile) {
    this.setState({
      isLoading: true
    });

    var data = {
      shopstatusid: shopstatusid,
      options: options
    }
    var url = CONFIG.API_URL + 'shop/download';
    axios.request({ url: url, method: 'post', data, responseType: 'blob' })

      .then((blob) => {
        debugger;
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement('a');
        link.href = url;

        var filename = options == 'completed' ? completedfile : errorfile;
        filename = filename.substring(filename.lastIndexOf('\\') + 1);

        link.setAttribute('download', filename);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
        this.setState({
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        alert(error.response.data);
      });
  }

  updateShopClick(scheduleid, rundate, status) {
    this.setState({
      isLoading: true,
    });

    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }

    axios.put(CONFIG.API_URL + 'shop/schedule/restore/' + scheduleid)
      .then(res => {
        const data = {
          name: (localStorage.getItem('name') === 'null') ? null : localStorage.getItem('name'),
          status: (localStorage.getItem('status') === 'null') ? null : localStorage.getItem('status'),
          rundate: (localStorage.getItem('rundate') === 'null') ? null : moment(localStorage.getItem('rundate')).format("L"),
          page: this.state.activePage, //(!localStorage.getItem('searchpaginationpage') || (localStorage.getItem('searchpaginationpage') === 'null')) ? 1 : localStorage.getItem('searchpaginationpage'),
          pagelength: 10
        }
    
        this.apidata_call(data);
        //this.componentDidMount();
        this.scheduledShops(rundate, status);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          localStorage.removeItem('clientid');
          localStorage.removeItem('clientname');
          localStorage.removeItem('clientlogo');
          localStorage.removeItem('role');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }

  deleteShopClick() {
    this.setState({
      isLoading: true
    });

    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }

    axios.request({
      url: CONFIG.API_URL + 'shop/schedule/' + this.state.scheduleid,
      method: 'delete',
    })
      .then(res => {
        //this.componentDidMount();
        const data = {
          name: (localStorage.getItem('name') === 'null') ? null : localStorage.getItem('name'),
          status: (localStorage.getItem('status') === 'null') ? null : localStorage.getItem('status'),
          rundate: (localStorage.getItem('rundate') === 'null') ? null : moment(localStorage.getItem('rundate')).format("L"),
          page: this.state.activePage, //(!localStorage.getItem('searchpaginationpage') || (localStorage.getItem('searchpaginationpage') === 'null')) ? 1 : localStorage.getItem('searchpaginationpage'),
          pagelength: 10
        }
    
        this.apidata_call(data);

        this.scheduledShops(this.state.rundate, this.state.status);
        this.setState({ scheduleid: '', rundate: '', status: '', open: false })
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        });
        alert(err);
      });
  }

  errorClick(shopid, scheduleid, lastrundate) {
    this.setState({
      isLoading: true
    });
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    var data = {
      shopid: shopid,
      scheduleid: scheduleid,
      rundate: lastrundate
    }
    axios.post(CONFIG.API_URL + 'shop/schedule/errorcodes', data)
      .then(res => {

        const errorData = res.data;
        this.setState({ isLoading: false, errorData });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        alert(error.response.data);
      });
  }

  handlePageChange(pageNumber) {
    window.scrollTo(0, 0);
    localStorage.setItem('searchpaginationpage', pageNumber ? pageNumber : null);

    this.setState({ activePage: pageNumber, isLoading: true, expanded: false, errorexpanded: false });
    const data = {
      name: (localStorage.getItem('name') === 'null') ? null : localStorage.getItem('name'),
      status: (localStorage.getItem('status') === 'null') ? null : localStorage.getItem('status'),
      rundate: (localStorage.getItem('rundate') === 'null') ? null : moment(localStorage.getItem('rundate')).format("L"),
      page: pageNumber,
      pagelength: 10
    }
    data.Page = pageNumber;

    this.apidata_call(data, true);
  }

  apidata_call(data, isPageChanged = false) {
    if (!isPageChanged) {
      data.page = 1;
      localStorage.setItem('searchpaginationpage', null);
    }

    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'shop/search', data)
      .then(res => {

        const searchdata = res.data;
        const searchshopData = res.data.shops;
        console.log(searchshopData);
        this.setState({ isLoading: false, searchdata, searchshopData });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        alert(error.response.data);
      });
  }

  componentDidMount() {
    this.setState({
      isLoading: true, expanded: false,
      errorexpanded: false
    });

    const data = {
      name: (localStorage.getItem('name') === 'null') ? null : localStorage.getItem('name'),
      status: (localStorage.getItem('status') === 'null') ? null : localStorage.getItem('status'),
      rundate: (localStorage.getItem('rundate') === 'null') ? null : moment(localStorage.getItem('rundate')).format("L"),
      page: this.state.activePage, //(!localStorage.getItem('searchpaginationpage') || (localStorage.getItem('searchpaginationpage') === 'null')) ? 1 : localStorage.getItem('searchpaginationpage'),
      pagelength: 10
    }

    this.apidata_call(data);
  }

  render() {
    document.title = 'SearchResults';
    const { isLoading, expanded, errorexpanded, searchshopData, scheduledShops, searchdata, errorData, open } = this.state;
    const searchdataPagination = searchdata.pagination;
    return (
      <div className="Schedules homepage search-result">
        <Container className="body-content">
          <Row>
            <Col md={3}>
              <Sidebar parentMethod={this.handler} parm={this.props} />
            </Col>
            <Col md={9}>
              {isLoading === true ? <div className="loading"></div> : ''}
              <h4 className="graph-view">Search Results for {localStorage.getItem('name') === "null" ? ' ' : ' the Keyword "' + localStorage.getItem('name') + '"'}{localStorage.getItem('status') === 'null' ? '' : (localStorage.getItem('name') !== "null" ? ' with ' : '') + 'Status "' + localStorage.getItem('status') + '"'}{localStorage.getItem('rundate') !== "null" ? ' on ' : ''}{localStorage.getItem('rundate') === "null" ? '' : '"' + moment(localStorage.getItem('rundate')).format("L") + '"'} <AddShop parentMethod={this.handler1} parm={this.props} /></h4>
              <Table className="schedule-top">
                <TableHead>
                  <TableRow>
                    <TableCell>Run Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Run Count</TableCell>
                    {/* <TableCell></TableCell> */}
                  </TableRow>
                </TableHead>
              </Table>
              {searchshopData.length > 0 ? searchshopData.map(shop => (
                <div className="odd-even-grid">
                  <ExpansionPanel expanded={expanded === 'panel1' + shop.id} onChange={this.handleChange('panel1' + shop.id)} className="schedule-grid">
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      onClick={() => { this.scheduledShops(shop.rundate, shop.status) }}>
                      <Typography >{moment(shop.rundate).format("L")}</Typography>
                      <Typography className={shop.status}>{shop.status}</Typography>
                      <Typography className='searchcount'>{shop.callcount}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="expandable-view">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Shop Name</TableCell>
                            <TableCell align="left" className="src-batch-id">Batch Id</TableCell>
                            <TableCell align="left">Last Run Date</TableCell>
                            <TableCell align="left" className="search-shopgrid-shopname">Next Run Date</TableCell>
                            {/* <TableCell align={shop.status === 'Failed' ? 'left' : 'center'}>Run Count</TableCell> */}
                            <TableCell align="left">Run Count</TableCell>
                            <TableCell align="left"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(scheduledShops.length > 0) && (shop.status !== 'Failed') ? scheduledShops.map(scheduled => (
                            <TableRow key={scheduled.id}>
                              <TableCell component="th" scope="row">{scheduled.shopname}</TableCell>
                              <TableCell align="left" className="src-batch-id">{scheduled.batchid}</TableCell>
                              <TableCell align="left">{scheduled.lastrundate ? moment(scheduled.lastrundate).format("MM/DD/YYYY hh:00 A") : <div className="hypen-left">-</div>}</TableCell>
                              <TableCell align="left">{scheduled.nextrundate ? moment(scheduled.nextrundate).format("MM/DD/YYYY hh:00 A") : <div className="hypen-left">-</div>}</TableCell>
                              <TableCell align="left">{scheduled.callcount}</TableCell>
                              <TableCell align="left">
                                {scheduled.downloadfile ?
                                  <Button className="override-btn" onClick={event => this.downloadFile(scheduled.shopstatusid, scheduled.downloadfile, scheduled.completedfile, scheduled.errorfile)}><img src={download1} alt="download" /></Button>
                                  : ''}
                                {(scheduled.statusname === 'Active') || scheduled.statusname === 'Inactive' ?
                                  <Button onClick={event => this.handleClickOpen(scheduled.scheduleid, shop.rundate, shop.status)} className="delete-icon override-btn"><img src={delete1} alt="delete" /></Button>
                                  : <Button onClick={event => this.updateShopClick(scheduled.scheduleid, shop.rundate, shop.status)} className="delete-icon override-btn"><img src={restore} alt="delete" /></Button>
                                }

                              </TableCell>
                            </TableRow>
                          ))
                            : (scheduledShops.length > 0) && (shop.status === 'Failed') ?
                              <TableRow><TableCell colSpan="5" className="no-padd">
                                {scheduledShops.map(scheduled => (
                                  <ExpansionPanel expanded={errorexpanded === 'error1' + scheduled.id} onChange={this.errorhandleChange('error1' + scheduled.id)}>
                                    <ExpansionPanelSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1bh-content"
                                      id="panel1bh-header"

                                      onClick={shop.status === 'Failed' ? () => { this.errorClick(scheduled.shopid, scheduled.scheduleid, moment(shop.rundate).format("L")) } : ''}
                                    >
                                      <Typography className="sche-shopname">{scheduled.shopname}</Typography>
                                      <TableCell align="left" className="src-batch-id">{scheduled.batchid}</TableCell>
                                      <Typography className="sche-lrundate">{scheduled.lastrundate ? moment(scheduled.lastrundate).format("MM/DD/YYYY hh:00 A") : <div className="hypen">-</div>}</Typography>
                                      <Typography className="sche-nrundate">{scheduled.nextrundate ? moment(scheduled.nextrundate).format("MM/DD/YYYY hh:00 A") : <div className="hypen">-</div>}</Typography>
                                      <Typography className="sche-count">{scheduled.callcount}</Typography>
                                      <Typography className="sche-delet">
                                        {scheduled.downloadfile ?
                                          <Button className="override-btn" onClick={event => this.downloadFile(scheduled.shopstatusid, scheduled.downloadfile, scheduled.completedfile, scheduled.errorfile)}><img src={download1} alt="download" /></Button>
                                          : ''}
                                        {(scheduled.statusname === 'Active') || scheduled.statusname === 'Inactive' ?
                                          <Button onClick={event => this.handleClickOpen(scheduled.scheduleid, shop.rundate, shop.status)} className="delete-icon override-btn"><img src={delete1} alt="delete" /></Button>
                                          : <Button onClick={event => this.updateShopClick(scheduled.scheduleid, shop.rundate, shop.status)} className="delete-icon override-btn"><img src={restore} alt="delete" /></Button>
                                        }
                                      </Typography>

                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="error-panel">
                                      <Table>
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Error Codes</TableCell>
                                            <TableCell>Calls</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {errorData.length > 0 ? errorData.map(error => (
                                            <TableRow key={error.errorcode}>
                                              <TableCell component="th" scope="row">
                                                {/* {error.errorcode} - {error.errormessage} */}
                                                {error.errormessage}
                                              </TableCell>
                                              <TableCell>{error.callscount}</TableCell>
                                            </TableRow>
                                          ))
                                            : <TableRow><TableCell colSpan="2" align="center" className="no-records">No records Found</TableCell></TableRow>
                                          }
                                        </TableBody>
                                      </Table>
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel>
                                ))}
                              </TableCell></TableRow>

                              : <TableRow><TableCell colSpan="6" align="center" className="no-records">There is no Schedules found.</TableCell></TableRow>
                          }
                        </TableBody>
                      </Table>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>

              )) : <p className="no-data-style">There is no results found.</p>}
              {searchdataPagination && searchdataPagination.totalrecords > 0 ?
                <div className="float-left">
                  <div className="sho"> Showing from
                  {localStorage.getItem('searchpaginationpage') > 1 ? ' ' + (localStorage.getItem('searchpaginationpage') - 1)
                      : ''
                    }1 to
                  {
                      ((searchshopData.length === 10) && (!localStorage.getItem('searchpaginationpage'))) ? ' 10 ' :
                        ((searchshopData.length === 10) && (localStorage.getItem('searchpaginationpage') == 'null')) ? ' 10 '
                          :
                          ((searchshopData.length === 10) && (localStorage.getItem('searchpaginationpage') > 0)) ? ' ' + (localStorage.getItem('searchpaginationpage') + '0') + ' '
                            : ''
                    }
                    {
                      ((searchshopData.length < 10) && (localStorage.getItem('searchpaginationpage') > 1)) ? ' ' + (localStorage.getItem('searchpaginationpage') - 1) + '' + searchshopData.length + ' '
                        : ''
                    }
                    {((searchshopData.length < 10) && (!localStorage.getItem('searchpaginationpage'))) ? ' ' + searchshopData.length + ' ' : ''}
                    {((searchshopData.length < 10) && ((localStorage.getItem('searchpaginationpage') == 'null') || (localStorage.getItem('searchpaginationpage') == '0'))) ? ' ' + searchshopData.length + ' ' : ''}
                    of {searchdataPagination.totalrecords} entries.</div>
                </div> : ''}
              {searchdataPagination && searchdataPagination.totalrecords > 10 ?
                <div className="float-right">
                  <div>
                    <Pagination
                      hideDisabled
                      activePage={this.state.activePage}
                      itemsCountPerPage={searchdataPagination.pagelength}
                      totalItemsCount={searchdataPagination.totalrecords}
                      pageRangeDisplayed={10}
                      onChange={this.handlePageChange.bind(this)}
                      nextPageText={<ArrowRight />}
                      prevPageText={<ArrowLeft />}
                      firstPageText={<FastRewind />}
                      lastPageText={<FastForward />}
                    />
                  </div>
                </div> : ''
              }
              <div className="clearfix"></div>
            </Col>
          </Row>
        </Container>

        <Dialog
          open={open}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="confirm">
          <DialogTitle id="alert-dialog-title">Remove Shop</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.deleteShopClick} color="primary" autoFocus>
              Yes
          </Button>
            <Button onClick={this.handleClose} color="danger" >
              No
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}


export default SearchResults;