import React, { Component } from 'react';
import './Announcements.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button } from 'reactstrap';
import axios from 'axios';
import moment from 'moment';
import { CONFIG } from '../../utils/config';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Pagination from "react-js-pagination";
import delete1 from './../../Assets/Img/delete.png';
import restore from './../../Assets/Img/restore.png';
import edit1 from './../../Assets/Img/edit.png';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import FastForward from '@material-ui/icons/FastForward';
import FastRewind from '@material-ui/icons/FastRewind';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



class Announcements extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: false,
            announcementData: [],
            open: false,
            clientIds: [],
            selectedclientIds: [],
            edit: false,
            isLoading: false,
            announcementEditData: [],
            activePage: 1,//(!localStorage.getItem('annpaginationpage') || (localStorage.getItem('annpaginationpage') === 'null')) ? 1 : localStorage.getItem('annpaginationpage'),
            deletepop: false,
            AnnouncementID: null,
        };
        localStorage.setItem('annpaginationpage', 1);
        this.deleteAnnouncementClick = this.deleteAnnouncementClick.bind(this);
        this.deletehandleClose = this.deletehandleClose.bind(this);
        this.deletehandleClickOpen = this.deletehandleClickOpen.bind(this);
        this.editAnnouncementClick = this.editAnnouncementClick.bind(this);
        this.addAnnouncementClick = this.addAnnouncementClick.bind(this);
        this.updateAnnouncementClick = this.updateAnnouncementClick.bind(this);
        this.addAnnPopup = this.addAnnPopup.bind(this);
        this.editAnnPopup = this.editAnnPopup.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseEdit = this.handleCloseEdit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleEditChange = this.handleEditChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);

    }
    deletehandleClickOpen(AnnouncementID) {
        this.setState({
            deletepop:true,
            AnnouncementID : AnnouncementID
        });
      }
    
      deletehandleClose() {
        this.setState({
            deletepop:false
        });
      }
    handleChange(event) {
        this.setState({ selectedclientIds: event.target.value });
    }
    handleEditChange(event) {
        
        let announcementEditData = this.state.announcementEditData;
        announcementEditData.clientidsinarray = event.target.value
        //   selectedclientIds1 = event.target.value;
        this.setState({ announcementEditData });
    }
    editAnnouncementClick(event, errors, values) {
        
        let announcementEditData = this.state.announcementEditData;
        if (errors.length > 0) {
            console.log(errors);
        }
        else {
            const data = {
                "announcementid": announcementEditData.announcementid,
                "title": values.title,
                "description": values.description,
                "clientids": announcementEditData.clientidsinarray.length > 0 ? announcementEditData.clientidsinarray.toString() : null
            }
            this.setState({ isLoading: true });
            axios.put(CONFIG.API_URL + 'admin/announcement', data)
                .then(res => {
                    // announcementEditData = [];
                    this.setState({
                        isLoading: false, edit: false,
                        // announcementEditData
                    });
                    this.componentDidMount();


                })
                .catch(err => {
                    this.setState({ isLoading: false });
                    if (err.response.status === 401) {
                        localStorage.removeItem('accessKey');
                        localStorage.removeItem('clientid');
                        localStorage.removeItem('clientname');
                        localStorage.removeItem('clientlogo');
                        localStorage.removeItem('role');
                        const { from } = this.props.location.state || { from: { pathname: "/" } };
                        this.props.history.push(from);
                    }
                    else {
                        alert(err.response.data);
                    }

                });

        }

        this.setState({ errors, values });
        
    }
    editAnnPopup(AnnouncementID) {
        let announcementEditData = this.state.announcementEditData;
        let edit = this.state.edit;
        
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'admin/announcement/' + AnnouncementID)
            .then(res => {
                //let announcementEditData = this.state.announcementEditData;
                announcementEditData = res.data;
                edit = true;
                this.setState({
                    edit,
                    announcementEditData
                })

            })

            .catch((err) => {

                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    localStorage.removeItem('clientid');
                    localStorage.removeItem('clientname');
                    localStorage.removeItem('clientlogo');
                    localStorage.removeItem('role');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }



            });



    }
    addAnnPopup() {
        
        this.setState({
            open: true
        })
    }
    handleClose() {
        let selectedclientIds = this.state.selectedclientIds;
        selectedclientIds = [];
        this.setState({
            open: false,
            selectedclientIds
        })
    }
    handleCloseEdit() {
        this.setState({
            edit: false
        })
    }

    addAnnouncementClick(event, errors, values) {        
        if (errors.length > 0) {
            console.log(errors);
        }
        else {
            const data = {
                "title": values.title,
                "description": values.description,
                "clientids": this.state.selectedclientIds.length > 0 ? this.state.selectedclientIds.toString() : null
            }
            this.setState({ isLoading: true });
            axios.post(CONFIG.API_URL + 'admin/announcement', data)
                .then(res => {
                    let selectedclientIds = this.state.selectedclientIds;
                    selectedclientIds = [];
                    this.setState({ isLoading: false, open: false, selectedclientIds });
                    this.componentDidMount();


                })
                .catch(err => {
                    let selectedclientIds = this.state.selectedclientIds;
                    selectedclientIds = [];
                    this.setState({ isLoading: false, selectedclientIds });
                    if (err.response.status === 401) {
                        localStorage.removeItem('accessKey');
                        localStorage.removeItem('clientid');
                        localStorage.removeItem('clientname');
                        localStorage.removeItem('clientlogo');
                        localStorage.removeItem('role');
                        const { from } = this.props.location.state || { from: { pathname: "/" } };
                        this.props.history.push(from);
                    }
                    else {
                        alert(err.response.data);
                    }

                });

        }

        this.setState({ errors, values });
    }
    updateAnnouncementClick(AnnouncementID) {
        this.setState({ isLoading: true });
        
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.put(CONFIG.API_URL + 'admin/announcement/restore/' + AnnouncementID)
            .then(res => {


                this.componentDidMount();
                this.setState({ isLoading: false });
            })

            .catch((err) => {

                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    localStorage.removeItem('clientid');
                    localStorage.removeItem('clientname');
                    localStorage.removeItem('clientlogo');
                    localStorage.removeItem('role');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }



            });

    }
    deleteAnnouncementClick(AnnouncementID) {
        this.setState({
            isLoading: true
        });
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        axios.request({
            url: CONFIG.API_URL + 'admin/announcement/' + this.state.AnnouncementID,
            method: 'delete',
        })
            .then(res => {

                this.setState({AnnouncementID :'', deletepop:false})
                this.componentDidMount();
            })

            .catch((err) => {
                this.setState({
                    isLoading: false
                });
                alert(err);


            });

    }
    handlePageChange(pageNumber) {
        window.scrollTo(0, 0);
        localStorage.setItem('annpaginationpage', pageNumber ? pageNumber : null);
        this.setState({ activePage: pageNumber, isLoading: true });
        const data = {
            page: pageNumber,
            pagelength: 10
        }
        data.Page = pageNumber;

        this.apidata_call(data);
    }
    apidata_call(data) {
        const token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        axios.post(CONFIG.API_URL + 'admin/announcements', data)
            .then(res => {
                const announcementData = res.data
                this.setState({
                    isLoading: false,
                    activePage: announcementData.pagination.page,
                    announcementData
                });


            })
            .catch((err) => {
                this.setState({
                    isLoading: false
                });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    localStorage.removeItem('clientid');
                    localStorage.removeItem('clientname');
                    localStorage.removeItem('clientlogo');
                    localStorage.removeItem('role');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }

            });
    }
    componentDidMount() {
        this.setState({
            isLoading: true
        });
        const data = {
            page: this.state.activePage,//(!localStorage.getItem('annpaginationpage') || (localStorage.getItem('annpaginationpage') === 'null')) ? 1 : localStorage.getItem('annpaginationpage'),
            pagelength: 10
        }
        this.apidata_call(data);
        axios.post(CONFIG.API_URL + 'admin/clients')
            .then(res => {
                const clientIds = res.data
                this.setState({
                    isLoading: false,
                    clientIds
                });


            })
            .catch((err) => {
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    localStorage.removeItem('clientid');
                    localStorage.removeItem('clientname');
                    localStorage.removeItem('clientlogo');
                    localStorage.removeItem('role');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }

            });



    }

    render() {
        document.title = 'Announcements';
        const { isLoading, announcementData, clientIds, selectedclientIds, deletepop, open, edit, announcementEditData } = this.state;
        const announcementsData = announcementData.announcements;
        const announcementsPagination = announcementData.pagination;
        return (
            <div className="Announcements homepage">
                {isLoading ?
                    <div className="loading center"></div>
                    : ''
                }
                <h4 class="graph-view">Announcements<div class="AddShop"><button onClick={this.addAnnPopup} class="add-new-shop btn btn-secondary"><i>[+]</i>Add New</button></div></h4>
                <div className="health-sources announcemnet-table">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Created Date</TableCell>
                                <TableCell>Modified Date</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {announcementsData ? announcementsData.map(announcement => (
                                <TableRow key={announcement.announcementid} className={announcement.isactive ? 'active' : 'disable'}>
                                    <TableCell component="th" scope="row">
                                        {announcement.title}
                                    </TableCell>
                                    <TableCell>
                                        {announcement.description}
                                    </TableCell>
                                    <TableCell>{announcement.createddate ? moment(announcement.createddate).format("L, LT") : <div className="hypen">-</div>}</TableCell>
                                    <TableCell>{announcement.modifieddate  ? moment(announcement.modifieddate).format("L, LT") : <div className="hypen">-</div>}</TableCell>
                                    <TableCell className="edit-btn-ann">
                                        {announcement.isactive ?
                                            <Button onClick={event => this.editAnnPopup(announcement.announcementid)} className="delete-icon"><img src={edit1} alt="edit" /></Button>
                                            : ''}
                                    </TableCell>
                                    <TableCell className="delete-restore">
                                        {announcement.isactive ?
                                            <Button className="delete-icon" onClick={event => this.deletehandleClickOpen(announcement.announcementid)}><img src={delete1} alt="delete" /></Button>
                                            : <Button className="delete-icon" onClick={event => this.updateAnnouncementClick(announcement.announcementid)}><img src={restore} alt="restore" /></Button>
                                        }

                                    </TableCell>

                                </TableRow>
                            )) : <TableRow ><TableCell colspan="5" align="center" className="no-records">No records Found</TableCell></TableRow>}
                        </TableBody>
                    </Table>
                    {announcementsPagination && announcementsPagination.totalrecords > 0 ?
                                    <div className="float-left">
                                       <div className="sho"> Showing from {localStorage.getItem('annpaginationpage') > 1 ? ' ' + localStorage.getItem('annpaginationpage') - 1 : ''}1 to {(announcementsData.length === 10) && (!localStorage.getItem('annpaginationpage')) ? ' ' + 10 : (announcementsData.length === 10) && (localStorage.getItem('annpaginationpage') > 0) ? ' ' + localStorage.getItem('annpaginationpage')+ '0' : ''}{(announcementsData.length < 10) && (localStorage.getItem('annpaginationpage') > 1) ? ' '+(localStorage.getItem('annpaginationpage') - 1) + ''+ announcementsData.length +'' : ''}{(announcementsData.length < 10) && (!localStorage.getItem('annpaginationpage')) ? announcementsData.length : ''} of {announcementsPagination.totalrecords} entries.</div>
                            </div> : ''}
                {announcementsPagination && announcementsPagination.totalrecords > 10 ?
                    <div className="float-right">
                        <div>
                            <Pagination
                                hideDisabled
                                activePage={this.state.activePage}
                                itemsCountPerPage={announcementsPagination.pagelength}
                                totalItemsCount={announcementsPagination.totalrecords}
                                pageRangeDisplayed={10}
                                onChange={this.handlePageChange.bind(this)}
                                nextPageText={<ArrowRight />}
                                prevPageText={<ArrowLeft />}
                                firstPageText={<FastRewind />}
                                lastPageText={<FastForward/>}
                            />
                        </div>
                    </div> : ''
                }
                <div class="clearfix"></div>
                </div>
                
                <Dialog
                    open={open}
                    //onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="add-new-ann"
                >
                    <AvForm onSubmit={this.addAnnouncementClick.bind(this)}>
                        <DialogTitle id="alert-dialog-title" className="title-popup">Add Announcement</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">

                                <AvField name="title" label="Title" type="text" validate={{ 
                                    required:  {value:true, errorMessage: 'Please enter a Title.'}
                                     }} />
                                <AvField name="description" label="Description" type="textarea" validate={{ 
                                    required:  {value:true, errorMessage: 'Please enter a Description.'}
                                     }} />

                                <FormControl className="client-name">
                                    <InputLabel htmlFor="select-multiple">Clients</InputLabel>
                                    <Select
                                        multiple
                                        value={selectedclientIds}
                                        onChange={this.handleChange}
                                        input={<Input id="select-multiple" />}
                                        MenuProps={MenuProps}
                                    >
                                        {clientIds.map(name => (
                                            <MenuItem key={name.clientid} value={name.clientid}>
                                                {name.clientname}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className="display-block">
                            <Button type="submit" color="primary" className="search-btn">Submit</Button>
                            <Button onClick={this.handleClose} color="primary" className="reset-btn">Cancel</Button>
                        </DialogActions>
                    </AvForm>
                </Dialog>

                <Dialog
                    open={edit}
                    className="add-new-ann"
                    //onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <AvForm onSubmit={this.editAnnouncementClick.bind(this)}>
                        <DialogTitle id="alert-dialog-title" className="title-popup">Add Announcement</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">

                                <AvField name="title" value={announcementEditData ? announcementEditData.title : ''} label="Title" type="text" validate={{ required: true }} />
                                <AvField name="description" value={announcementEditData ? announcementEditData.description : ''} label="Description" type="textarea" validate={{ required: true }} />

                                <FormControl className="client-name">
                                    <InputLabel htmlFor="select-multiple">Clients</InputLabel>
                                    <Select
                                        multiple
                                        value={announcementEditData ? announcementEditData.clientidsinarray : []}
                                        onChange={this.handleEditChange}
                                        input={<Input id="select-multiple" />}
                                        MenuProps={MenuProps}
                                    >
                                        {clientIds.map(name => (
                                            <MenuItem key={name.clientid} value={name.clientid}>
                                                {name.clientname}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className="display-block">
                            <Button type="submit" color="primary" className="search-btn">Submit</Button>
                            <Button onClick={this.handleCloseEdit} color="primary" className="reset-btn">Cancel</Button>
                        </DialogActions>
                    </AvForm>
                </Dialog>
                <Dialog
        open={deletepop}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="confirm"
      >
        <DialogTitle id="alert-dialog-title">Remove Announcement</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this Announcement?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.deleteAnnouncementClick} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={this.deletehandleClose} color="danger" >
            No
          </Button>
        </DialogActions>
      </Dialog>
            </div>
        );
    }
}


export default Announcements;